<template>
<v-text-field label="Title*" variant="filled" v-model="editItem.title" :error-messages="error.title" @input="() => remove_error('title')" ref="titleField"></v-text-field>
<v-autocomplete label="Category Name*" variant="filled" v-model="this.categoryName" :items="activeCategories" :menu-props="{ minWidth: '300px' }" @update:model-value="handleUserChange" :error-messages="error.category_id" ref="categoryNameField">
</v-autocomplete>
<v-text-field label="Raffle Code" variant="filled" v-model="editItem.raffle_code" :error-messages="error.raffle_code" @input="() => remove_error('raffle_code')" ref="raffleCodeField" v-if="!btnNameChange && editItem.is_cross_selling" readOnly></v-text-field>
<v-text-field label="Total Entries*" class="mb-4" variant="filled" v-model="editItem.total_entries" :error-messages="error.total_entries" @input="() => remove_error('total_entries')" ref="totalEntriesField"></v-text-field>
<v-text-field label="Retail Price*" variant="filled" v-model="editItem.retail_price" :error-messages="error.retail_price" @input="() => remove_error('retail_price')" ref="retailPriceField"></v-text-field>
<v-select label="Status" :items=statusItem v-model="editItem.status" :error-messages="error.status_id" @input="() => remove_error('status_id')" ref="statusIdField"></v-select>
<v-text-field label="Tickets Sold*" variant="filled" v-model="editItem.tickets_sold" :error-messages="error.tickets_sold" @input="calculateTicketsLeft" ref="ticketsSoldField" :readOnly="!btnNameChange"></v-text-field>
<v-select label="Is Charity" :items="booleanItems" v-model="editItem.is_charity" :error-messages="error.is_charity" @input="() => remove_error('is_charity')" ref="isCharityField"></v-select>
<v-select label="Publish" :items="booleanItems" v-model="editItem.publish" :error-messages="error.publish" @input="() => remove_error('publish')" ref="publishField"></v-select>
<v-select label="Is Cross Selling" :items="booleanItems" v-model="editItem.is_cross_selling" :readonly="!btnNameChange && !editItem.is_cross_selling" :error-messages="error.is_cross_selling" @input="() => remove_error('is_cross_selling')" ref="isLiveField"></v-select>
<v-select label="Is Credit Accepted" :items="booleanItems" v-model="editItem.is_credit_accepted" :error-messages="error.is_credit_accepted"></v-select>
<v-select label="Is Discount Accepted" :items="booleanItems" v-model="editItem.is_discount_accepted" :error-messages="error.is_discount_accepted"></v-select>
<v-select label="Is Free Raffle" :items="booleanItems" v-model="editItem.is_free_raffle" :error-messages="error.is_free_raffle" @input="() => remove_error('is_free_raffle')" ref="isFreeRaffleField"></v-select>
<v-text-field label="Entry Cost gbp*" class="mb-4" variant="filled" v-model="editItem.entry_cost_gbp" :error-messages="error.entry_cost_gbp" @input="() => remove_error('entry_cost_gbp')" ref="entryCostGbpField"></v-text-field>
<v-text-field label="Slug*" variant="filled" v-model="editItem.slug" :error-messages="error.slug" @input="() => remove_error('slug')" ref="slugField"></v-text-field>
<v-textarea rows="1" label="Specification*" auto-grow variant="filled" v-model="editItem.specification" :error-messages="error.specification" @input="() => remove_error('specification')" ref="specificationField"></v-textarea>
<div class="drawDateTxt mb-4">
    <label for="">Drawing In*:</label>
</div>

<div class="row mb-4">
    <div class="col-6">
        <label for="datepicker" class="selectDateTxt">Select Date:</label>
        <input type="date" id="datepicker" name="datepicker" @click="openDatePicker('datepicker1')" ref="datepicker1" v-model="selectedDrawDate" class="dateInputFields" :min="minDate">
    </div>
    <div class="col-6">
        <label for="timepicker" class="selectDateTxt">Select Time:</label>
        <input type="time" id="timepicker" @click="openTimePicker('timepicker1')" name="timepicker" ref="timepicker1" v-model="selectedDrawTime" class="dateInputFields">
    </div>
</div>

<v-textarea rows="1" label="Summary*" auto-grow variant="filled" v-model="editItem.summary" :error-messages="error.summary" @input="() => remove_error('summary')" ref="summaryField"></v-textarea>

<v-textarea rows="1" label="Description*" auto-grow variant="filled" v-model="editItem.description" :error-messages="error.description" @input="() => remove_error('description')" ref="descriptionField"></v-textarea>
<div class="drawDateTxt mb-4">
    <label for="">Raffle Expire*:</label>
</div>

<div class="row mb-4">
    <div class="col-6">
        <label for="datepicker" class="selectDateTxt">Select Date:</label>
        <input type="date" id="datepicker" @click="openDatePicker('datepicker2')" name="datepicker" ref="datepicker2" v-model="selectedRaffleDate" class="dateInputFields" :min="minDate">
    </div>
    <div class="col-6">
        <label for="timepicker" class="selectDateTxt">Select Time:</label>
        <input type="time" id="timepicker" @click="openTimePicker('timepicker2')" name="timepicker" ref="timepicker2" v-model="selectedRaffleTime" class="dateInputFields">
    </div>
</div>
<v-select label="Featured" :items="booleanItems" v-model="editItem.featured" :error-messages="error.featured" @input="() => remove_error('featured')" ref="featuredField"></v-select>
<v-text-field label="Market Value*" variant="filled" v-model="editItem.market_value" :error-messages="error.market_value" @input="() => remove_error('market_value')" ref="marketValueField"></v-text-field>
<v-text-field label="Year*" variant="filled" v-model="editItem.year" :error-messages="error.year" @input="() => remove_error('year')" ref="yearField"></v-text-field>
<v-text-field label="Reference Number*" variant="filled" v-model="editItem.ref_no" :error-messages="error.ref_no" @input="() => remove_error('ref_no')" ref="refNoField"></v-text-field>
<v-select label="Box papers" :items="booleanItems" v-model="editItem.box_papers" :error-messages="error.box_papers" @input="() => remove_error('box_papers')" ref="boxPapersField"></v-select>
<v-autocomplete label="Prize*" variant="filled" v-model="this.prizeName" :items="prizesOptions" :menu-props="{ minWidth: '300px' }" @update:model-value="handlePrizeChange" :error-messages="error.prize_id" ref="prizeNameField">
</v-autocomplete>
<v-autocomplete label="Consolation prize*" variant="filled" v-model="this.consolationName" :items="consolationPrizesOptions" :menu-props="{ minWidth: '300px' }" @update:model-value="handleConsolationChange" :error-messages="error.consolation_prize_id" ref="consolationNameField">
</v-autocomplete>
<v-text-field label="Cash Alternative*" variant="filled" v-model="editItem.cash_alternative" :error-messages="error.cash_alternative" @input="() => remove_error('cash_alternative')" ref="cashAlternativeField"></v-text-field>
<v-text-field label="Number of Winners*" variant="filled" v-model="editItem.no_winners" :error-messages="error.no_winners" @input="() => remove_error('no_winners')" ref="noWinnersField" :readOnly="checkWinner"></v-text-field>
<v-select label="Is Live Draw" class="mb-4" :items="booleanItems" v-model="editItem.is_live_draw" :error-messages="error.is_live_draw" @input="() => remove_error('is_live_draw')" ref="isLiveDrawField"></v-select>
<v-select label="Is Unlimited Entries" class="mb-4" :items="booleanItems" v-model="editItem.is_unlimited_entries" :error-messages="error.is_unlimited_entries" @input="() => remove_error('is_unlimited_entries')" ref="isUnlimitedEntriesField"> </v-select>
<v-text-field label="Total Entries Per Player*" class="mb-4" variant="filled" v-model="editItem.total_entries_per_player" :error-messages="error.total_entries_per_player" @input="() => remove_error('total_entries_per_player')" ref="totalEntriesPerPlayerField"></v-text-field>
<v-text-field label="Meta Description" variant="filled" v-model="editItem.meta_description" :error-messages="error.meta_description" @input="() => remove_error('meta_description')" ref="metaDescriptionField"></v-text-field>
<v-text-field label="Meta Title" variant="filled" v-model="editItem.meta_title" :error-messages="error.meta_title" @input="() => remove_error('meta_title')" ref="metaTitleField"></v-text-field>
<v-text-field label="Product Source" variant="filled" v-model="editItem.product_source" :error-messages="error.product_source" @input="() => remove_error('product_source')" ref="productSourceField"></v-text-field>
<v-autocomplete label="Question*" variant="filled" v-model="this.question" :items="activeQuestions" :menu-props="{ minWidth: '300px' }" :error-messages="error.question_id" @update:model-value="handle_questionItem_name" ref="questionField">
</v-autocomplete>
<v-text-field label="Mini Image alt*" variant="filled" v-model="editItem.mini_image_alt" :error-messages="error.mini_image_alt" @input="() => remove_error('mini_image_alt')" ref="miniImageAltField"></v-text-field>
<v-text-field label="Main Image alt*" variant="filled" v-model="editItem.main_image_alt" :error-messages="error.main_image_alt" @input="() => remove_error('main_image_alt')" ref="mainImageAltField"></v-text-field>
<v-text-field label="Promo Image alt*" variant="filled" v-model="editItem.promo_image_alt" :error-messages="error.promo_image_alt" @input="() => remove_error('promo_image_alt')" ref="promoImageAltField"></v-text-field>

<v-text-field label="Width*" variant="filled" v-model="editItem.width" :error-messages="error.width" @input="() => remove_error('width')" ref="widthField"></v-text-field>
<v-text-field label="Height*" variant="filled" v-model="editItem.height" :error-messages="error.height" @input="() => remove_error('height')" ref="heightField"></v-text-field>
<v-text-field label="Length*" variant="filled" v-model="editItem.productLength" :error-messages="error.productLength" @input="() => remove_error('productLength')" ref="lengthField"></v-text-field>
<v-text-field label="Weight*" variant="filled" v-model="editItem.weight" :error-messages="error.weight" @input="() => remove_error('weight')" ref="weightField"></v-text-field>
<v-text-field label="Packages*" variant="filled" v-model="editItem.package" :error-messages="error.packages_count" @input="() => remove_error('packages_count')" ref="packageField"></v-text-field>
<v-text-field label="Details*" variant="filled" v-model="editItem.details" :error-messages="error.details" @input="() => remove_error('details')" ref="detailsField"></v-text-field>
<v-text-field label="Competition Details*" variant="filled" v-model="editItem.CompetitionDetails" :error-messages="error.CompetitionDetails" @input="() => remove_error('CompetitionDetails')" ref="competitionDetailsField"></v-text-field>

<div v-if="!btnNameChange" class="mb-3">
    <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
            <v-card>
                <v-card-title class="caption">Main Image</v-card-title>
                <v-img :src="img_url + editItem.main_image_url" height="150"></v-img>
            </v-card>
            <div>
                <v-btn v-if="showSaveButton" class="mt-3" color="blue" @click="this.img_upload('main_image_url')">{{
                        mainImgUpload ? 'Close' : 'Edit Main Image' }}</v-btn>
            </div>
        </v-col>
    </v-row>
</div>
<v-file-input accept="image/*" v-model="mainImageValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Main Image*" :error-messages="error.main_image_url" @change="this.handle_img_upload('main_image_url')" ref="mainImageUrlField" v-if="mainImgUpload"></v-file-input>
<div v-if="!btnNameChange" class="mb-3">
    <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
            <v-card>
                <v-card-title class="caption">Mini Image</v-card-title>
                <v-img :src="img_url + editItem.mini_image_url" height="150"></v-img>
            </v-card>
            <div>
                <v-btn v-if="showSaveButton" class="mt-3" color="blue" @click="this.img_upload('mini_image_url')">{{
                        miniImgUpload ? 'Close' : 'Edit Mini Image' }}</v-btn>
            </div>
        </v-col>
    </v-row>
</div>
<v-file-input accept="image/*" v-model="miniImageValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Mini Image*" :error-messages="error.mini_image_url" @change="this.handle_img_upload('mini_image_url')" ref="miniImageUrlField" v-if="miniImgUpload"></v-file-input>
<div v-if="!btnNameChange" class="mb-3">
    <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
            <v-card>
                <v-card-title class="caption">Promo Image</v-card-title>
                <v-img :src="img_url + editItem.promo_image_url" height="150"></v-img>
            </v-card>
            <div>
                <v-btn v-if="showSaveButton" class="mt-3" color="blue" @click="this.img_upload('promo_image_url')">{{ promoImgUpload ? 'Close' : 'Edit Promo Image'
                        }}</v-btn>
            </div>
        </v-col>
    </v-row>
</div>
<v-file-input accept="image/*" v-model="promoImageValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Promo Image*" :error-messages="error.promo_image_url" @change="this.handle_img_upload('promo_image_url')" ref="promoImageUrlField" v-if="promoImgUpload"></v-file-input>

<div v-if="!btnNameChange" class="mb-3">
    <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
            <v-card>
                <v-card-title class="caption">Cross Selling Main Image</v-card-title>
                <v-img :src="img_url + editItem.cross_selling_main_img" height="150"></v-img>
            </v-card>
            <div v-if="showSaveButton" class="mb-3">
                <v-btn class="mt-3" color="blue" @click="this.img_upload('CrossSellingMainImage')">{{ CrossSellingImageUpload ? 'Close' : (editItem.cross_selling_main_img ? 'Edit Cross Selling Image' : 'Add Cross Selling Image') }}</v-btn>
            </div>
        </v-col>
    </v-row>
</div>
<v-file-input accept="image/*" v-model="CrossSellingMainImageValue" prepend-icon="" prepend-inner-icon="mdi-camera" :label="`${JSON.parse(this.editItem.is_cross_selling) ? 'Cross Selling Main Image*' : 'Cross Selling Main Image'}`" :error-messages="error.cross_selling_main_img" @change="this.handle_img_upload('cross_selling_main_img')" ref="CrossSellingImageField" v-if="CrossSellingImageUpload"></v-file-input>

<div v-if="!btnNameChange">
    <v-row class="mb-3">
        <v-col cols="12" sm="6" md="4" lg="3" v-for="(thumbnail, index) in editItem.thumbnails" :key="index">
            <v-card>
                <v-card-title class="caption">Thumbnail {{ index + 1 }}</v-card-title>
                <v-img :src="img_url + thumbnail.newSignInKey" height="150"></v-img>
                <v-btn v-if="showSaveButton" icon small color="grey" class="delete-thumbnail" @click="deleteThumbnail(index)" style="position: absolute; top: 5px; right: 5px;">
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </v-card>
        </v-col>
    </v-row>
</div>
<div v-if="showSaveButton" class="mb-3">
    <v-btn class="mt-3" color="blue" @click="this.img_upload('thumbnail')">{{ thumbnailImgUpload ? 'Close' : 'Add Thumbnail' }}</v-btn>
</div>
<v-file-input accept="image/*" v-model="thumbnailImageValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Thumbnails" :error-messages="error.thumbnail" @change="this.handle_img_upload('thumbnail')" ref="thumbnailField" multiple v-if="thumbnailImgUpload"></v-file-input>

<v-row v-if="discountsData.length < 3 && showDiscountButton">
    <v-col>
        <h5 @click=open_discount_Inputs()>Discounts*:
            <v-btn density="default" color="primary" icon="mdi-plus" v-if="!openDiscountsPage"></v-btn>
        </h5>
    </v-col>
</v-row>

<div id="openDiscountsPage">
    <template v-if="openDiscountsPage">
        <v-text-field auto-grow variant="filled" label="Number of Entries*" rows="1" v-model="discountDetails.entries" :error-messages="error.entries" @input="remove_error('entries')"></v-text-field>
        <v-text-field auto-grow variant="filled" label="Discount*" rows="1" v-model="discountDetails.discount" :error-messages="error.discount" @input="remove_error('discount')"></v-text-field>
        <v-select label="Most Popular*" :items="booleanItems" v-model="discountDetails.mostPopular" :error-messages="error.mostPopular" @input="() => remove_error('mostPopular')"></v-select>

        <v-btn class="me-4 mb-4" color="blue" type="submit" @click=validate_discount_inputs() v-if="!editDiscountButton">
            save discounts details
        </v-btn>
        <v-btn class="me-4 mb-4" color="blue" type="submit" @click=validate_discount_inputs(discountDetails.serial_no) v-else>
            Update discounts detail
        </v-btn>
    </template>
</div>

<div>
    <h5 v-if="discountsData.length">Discounts:</h5>
    <v-container fluid v-if="discountsData.length">
        <v-row dense>
            <v-col v-for="(discount, index) in discountsData" :key="index" :cols="12" :lg="6">
                <v-card>
                    <v-card-text line-height=".75rem">
                        <p>Entries : {{ discount.entries }}</p>
                        <p>Discount : {{ discount.discount }}</p>
                        <p>Most Popular : {{ discount.mostPopular }}</p>
                    </v-card-text>
                    <v-card-actions padding="0">
                        <v-spacer></v-spacer>
                        <v-btn icon @click="edit_discount_item(discount.serial_no)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="delete_discount(discount.serial_no)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

    <div v-if="error.discountsData" :style="{ color: 'red' }">{{ error.discountsData }}</div>

</div>
<v-row class="mt-3">
    <v-col v-if="checkNumberOfWinner && showDrawWinnerButton && winnersData.length < this.editItem.no_winners">
        <h5 @click=open_winners_Inputs()>Winners ticket number:
            <v-btn density="default" color="primary" icon="mdi-plus" v-if="!openWinnersPage"></v-btn>
        </h5>
    </v-col>
</v-row>
<div id="openWinnersPage">
    <template v-if="openWinnersPage">
        <v-text-field auto-grow variant="filled" label="Ticket number*" rows="1" v-model="winnersDetails.ticket_number" :error-messages="error.ticket_number" @input="remove_error('ticket_number')"></v-text-field>
        <v-text-field auto-grow variant="filled" label="position*" rows="1" v-model="winnersDetails.position" :error-messages="error.position" @input="remove_error('position')"></v-text-field>
        <v-btn class="me-4 mb-4" color="blue" type="submit" @click=validate_winners_inputs() v-if="!editWinnerButton">
            save winners details
        </v-btn>
        <v-btn class="me-4 mb-4" color="blue" type="submit" @click=validate_winners_inputs(winnersDetails.serial_no) v-else>
            Update winners detail
        </v-btn>
    </template>
</div>
<div>
    <h5 v-if="winnersData.length">Winners ticket number:</h5>
    <v-container fluid v-if="winnersData.length">
        <v-row dense>
            <v-col v-for="(winner, index) in winnersData" :key="index" :cols="12" :lg="6">
                <v-card>
                    <v-card-text line-height=".75rem">
                        <p>Ticket number : {{ winner.ticket_number }}</p>
                        <p>position : {{ winner.position }}</p>
                    </v-card-text>
                    <v-card-actions padding="0" v-if="showDrawWinnerButton">
                        <v-spacer></v-spacer>
                        <v-btn icon @click="edit_winner_item(winner.serial_no)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="delete_winner(winner.serial_no)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
<div class="mt-10">
    <v-btn @click="validate_inputs()" v-if="btnNameChange" :disabled="buttonDisabled" color="blue">ADD</v-btn>
    <v-btn @click="validate_inputs()" :disabled="buttonDisabled" v-if="showSaveButton" color="blue">SAVE</v-btn>

    <v-btn v-if="showDrawWinnerButton && winnersData.length" :disabled="winnerbuttonDisabled" @click="add_winner_data()" color="blue" class="ml-10">
        Draw winner
    </v-btn>
    <v-btn @click="route_to_drawPage(editItem.sort_id)" v-if="!this.btnNameChange" color="blue" class="ml-10">Go To
        Live
        Draw</v-btn>
</div>

<div v-if="error.api" :style="{ color: 'red' }">{{ error.api }}</div>
<div v-if="error.winnerDraw" :style="{ color: 'red' }">{{ error.winnerDraw }}</div>

<v-dialog v-model="dialogForDelete" max-width="560px" persistent>
    <v-card>
        <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
            <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" @click="thumbnailDelete ? deleteThumbnailConfirm() : delete_batch_item()">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</v-dialog>
<logoutDia :showLogoutDialog="showLogoutDialog" />
<successMessageVue :message="successMessage" :showSnackbar="showSnackbar" />
</template>

<script>
import '@/assets/css/dateFormate.css'
import apiUrl from '../../api/allApis.js'
import vaildationMixin from "../../mixins/validation.js"
import successMessageVue from '../ReusableComponents/successMessage.vue'
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    markRaw
} from 'vue';
import timerMixin from "../../mixins/timer.js"
import imgUploadMixin from "../../mixins/imgUpload.js";
import moment from 'moment'
export default markRaw({
    mixins: [vaildationMixin, imgUploadMixin, jwtMixin, timerMixin],
    name: "Add",
    components: {
        successMessageVue,
        logoutDia
    },
    emits: ['data-changed'],

    data() {

        return {
            booleanItems: ["true", "false"],
            showLogoutDialog: false,
            img_url: 'https://mwc-storage-bucket.s3.ap-south-2.amazonaws.com/',
            statusItem: ["draft", "active", "inactive", "expired", "soldout", "completed", "drawing"],
            mainImageObj: {
                item: '',
                upload: false
            },
            miniImageObj: {
                item: '',
                upload: false
            },
            promoImageObj: {
                item: '',
                upload: false
            },
            CrossSellingMainImageObj: {
                item: '',
                upload: false
            },
            thumbnailImageObj: [],
            winnerDraw: false,
            showSaveButton: true,
            showDrawWinnerButton: false,
            showDiscountButton: false,
            showSnackbar: false,

            thumbnailDltIndex: '',
            thumbnailDelete: false,
            img_updated: false,
            mainImgUpload: false,
            miniImgUpload: false,
            promoImgUpload: false,
            CrossSellingImageUpload: false,
            thumbnailImgUpload: false,
            file: '',
            mainImageValue: '',
            miniImageValue: '',
            promoImageValue: '',
            CrossSellingMainImageValue: '',
            xlImageValue: '',
            displayedWinnerImageValue: '',
            thumbnailImageValue: [],
            signedUrl: '',
            deleteDetails: null,
            deleteId: null,
            dialogMessage: 'Are you sure you want to delete this item?',
            dialogForDelete: false,
            uploadedImages: [],
            openDiscountsPage: false,
            openWinnersPage: false,
            error: {
                raffle_code: '',
                quantity_of_items: '',
                main_image_url: '',
                summary: '',
                description: '',
                reserve_price: '',
                no_of_tickets: '',
                raffle_length: '',
                raffle_expire: '',
                raffle_added: '',
                featured: '',
                tickets_sold: '',
                tickets_left: '',
                ticket_start_int: '',
                category_id: '',
                condition_id: '',
                delivery_information_id: '',
                status_id: '',
                charity_id: '',
                charity_amount: '',
                title: '',
                product_source: '',
                retail_price: '',
                specification: '',
                target_price: '',
                seller_id: '',
                mini_image_url: '',
                facebook_description: '',
                facebook_title: '',
                credits_perTicket: '',
                extended: '',
                extended_days: '',
                is_cross_selling: '',
                is_credit_accepted: '',
                is_discount_accepted: '',
                tickets_per_entry: '',
                max_entries: '',
                is_charity: '',
                charity_color_override: '',
                is_flash: '',
                slug: '',
                video_url_mp4: '',
                video_length_mp4: '',
                video_url_webm: '',
                no_winners: '',
                jira_key_id: '',
                sponsor_id: '',
                main_image_alt: '',
                mini_image_alt: '',
                promo_image_alt: '',
                promo_image_url: '',
                drawing_in: '',
                postal_tickets: '',
                ticket_entries: '',
                is_unlimited_tickets: '',
                tickets: '',
                is_live_draw: '',
                is_sequential_tickets: '',
                is_auto_clone: '',
                auto_cloned: '',
                cloned_from_id: '',
                video_no_sound: '',
                video_live_drawurl: '',
                meta_description: '',
                meta_title: '',
                xl_image_alt: '',
                xl_image_url: '',
                is_free_raffle: '',
                is_new: '',
                charity_target_raised_amount: '',
                charity_approximate_donation: '',
                is_unlimited_entries: '',
                is_guaranteed_draw: '',
                autoraffle: '',
                custom_raffle_id: '',
                displayed_winner_description: '',
                displayed_winner_img_url: '',
                all_raffle_sort: '',
                f: '',
                is_unlimited_raffle: '',
                total_entries: '',
                total_entries_per_player: '',
                discounted_entry_cost_gbp: '',
                discounted_entry_percentage: '',
                disable_ticket_selector: '',
                youtube_link: '',
                hide_tickets_checkout: '',
                instant_win_image: '',
                no_of_top_prizes: '',
                cash_alternative: '',
                claim_category: '',
                super_raffle_img_url: '',
                publish: '',
                state: '',
                raffle_type: '',
                market_value: '',
                year: '',
                ref_no: '',
                box_papers: '',
                discount: '',
                entries: '',
                mostPopular: '',
                ticket_number: '',
                position: '',
                rafflecode_id: '',
                img_url: '',
                img_title: '',
                thumbnails: '',
                consolation_prize_id: '',
                prize_id: '',
                consolation_prize_value: '',
                question_id: '',
                winnerDraw: '',
                discountsData: '',
                api: '',
                details: '',
                packages_count: '',
                width: '',
                productLength: '',
                height: '',
                weight: '',
                CompetitionDetails: '',
            },
            discountDetails: {
                serial_no: '',
                entries: '',
                discount: '',
                mostPopular: '',
            },
            winnersDetails: {
                serial_no: '',
                ticket_number: '',
                position: '',
            },
            discountsData: [],
            editDiscountButton: false,
            winnersData: [],
            editWinnerButton: false,
            buttonDisabled: false,
            winnerbuttonDisabled: false,
            selectedRaffleDate: new Date().toISOString().substr(0, 10),
            selectedRaffleTime: new Date().toTimeString().slice(0, 5),
            selectedDrawDate: new Date().toISOString().substr(0, 10),
            selectedDrawTime: new Date().toTimeString().slice(0, 5),
            datePickerDialog: false,

            categoryName: null,
            OptionObject: {
                allId: '',
                allNames: ''
            },
            consolationName: null,
            consolationObject: {
                consolationID: '',
                consolationName: ''
            },
            oldData: null,
            prizeName: null,
            prizeObject: {
                consolationID: '',
                consolationName: '',
                prizeName: ''
            },
            question: null,
            questionObject: {
                questionId: '',
                question: '',
            },

        }
    },
    props: {
        editItem: Object,
        btnNameChange: Boolean
    },
    mounted() {
        this.updateDiscountButtonVisibility();
        this.updateSaveButtonVisibility();
        this.oldData = this.editItem.no_winners ? this.editItem.no_winners : null;
        this.updateDrawButtonVisibility();
        switch (this.editItem.status) {
            case 0:
                this.editItem.status = 'draft';
                break;
            case 1:
                this.editItem.status = 'active';
                break;
            case 2:
                this.editItem.status = 'inactive';
                break;
            case 3:
                this.editItem.status = 'expired';
                break;
            case 4:
                this.editItem.status = 'soldout';
                break;
            case 5:
                this.editItem.status = 'completed';
                break;
            case 12:
                this.editItem.status = 'drawing';
                break;
        }

        this.editItem.thumbnails.forEach(thumbnail => {
            thumbnail.upload = false;
        })
        if (this.btnNameChange) {
            this.miniImgUpload = true
            this.mainImgUpload = true
            this.promoImgUpload = true
            this.CrossSellingImageUpload = true
            this.thumbnailImgUpload = true

        }
        this.load_option_data();
        this.load_consolation_data();
        this.load_question_data();

        if (this.editItem.raffle_expire) {
            let drawDate = new Date(this.editItem.raffle_expire);
            this.selectedRaffleDate = drawDate.toISOString().substr(0, 10);
            const hours = drawDate.getUTCHours().toString().padStart(2, '0');
            const minutes = drawDate.getUTCMinutes().toString().padStart(2, '0');
            this.selectedRaffleTime = `${hours}:${minutes}`;
        }
        if (this.editItem.drawing_in) {
            let drawDate = new Date(this.editItem.drawing_in);
            this.selectedDrawDate = drawDate.toISOString().substr(0, 10);
            const hours = drawDate.getUTCHours().toString().padStart(2, '0');
            const minutes = drawDate.getUTCMinutes().toString().padStart(2, '0');
            this.selectedDrawTime = `${hours}:${minutes}`;
        }

        if (!this.btnNameChange) {
            this.OptionObject = this.allIdNameDetail.find(item => item.allId === this.editItem.category_id)
            if (this.OptionObject) {
                this.categoryName = this.OptionObject.allNames
            } else {
                this.categoryName = "Category Name not Available"
            }
            this.questionObject = this.allQuestionOptionDetail.find(item => item.questionId === this.editItem.question_id)
            if (this.questionObject) {
                this.question = this.questionObject.question
            } else {
                this.question = "Question not Available"
            }
            this.consolationObject = this.allConsolationDetail.find(item => item.consolationID === this.editItem.consolation_prize_id)
            if (this.consolationObject) {
                this.consolationName = this.consolationObject.consolationName
            } else {
                this.consolationName = "Name not Available"
            }
            this.prizeObjectObject = this.allConsolationDetail.find(item => item.consolationID === this.editItem.prize_id)
            if (this.prizeObjectObject) {
                this.prizeName = this.prizeObjectObject.prizeName
            } else {
                this.prizeName = "Name not Available"
            }
        }
        const storedDiscountsData = sessionStorage.getItem('discountDetails');
        if (storedDiscountsData) {
            let parsedData = JSON.parse(storedDiscountsData);
            if (Array.isArray(parsedData)) {
                this.discountsData = parsedData;
            } else if (typeof parsedData === 'object') {
                this.discountsData.push(parsedData);
            } else {
                this.discountsData = [];
            }
        } else {
            this.discountsData = []
        }
        const storedWinnersData = sessionStorage.getItem('winnersDetails');
        if (storedWinnersData) {
            let parsedData = JSON.parse(storedWinnersData);
            if (Array.isArray(parsedData)) {
                this.winnersData = parsedData;
                this.winnerDraw = true
            } else if (typeof parsedData === 'object') {
                this.winnersData.push(parsedData);
                this.winnerDraw = true
            } else {
                this.winnersData = [];
                this.winnerDraw = false
            }
        } else {
            this.winnersData = []
            this.winnerDraw = false
        }
    },
    computed: {
        ...mapGetters(['allIdNameDetail', 'allSellerOptionDetail', 'allConditionOptionDetail', 'allSponsorOptionDetail', 'allCustomRaffleOptionDetail', 'allCharityDatail', 'allDeliveryOptionDetail', 'allStatus', 'allConsolationDetail', 'allQuestionOptionDetail']),
        checkWinner() {
            return (this.openWinnersPage === true || this.winnersData.length > 0)
        },
        checkNumberOfWinner() {
            return (
                this.oldData == this.editItem.no_winners
            )
        },
        isDiscountsFormFilled() {
            return (
                (this.error.entries === '' || this.error.entries == undefined) &&
                (this.error.discount === '' || this.error.discount == undefined) &&
                (this.error.mostPopular === '' || this.error.mostPopular == undefined)
            )
        },
        activeQuestions() {
            return this.allQuestionOptionDetail
                .filter(item => item.active === "active")
                .map(item => item.question);
        },
        activeCategories() {
            return this.allIdNameDetail
                .filter(item => item.active === true)
                .map(item => item.allNames);
        },
        prizesOptions() {
            return this.allConsolationDetail
                .filter(item => item.physicalPrize === true)
                .map(item => item.prizeName);
        },
        consolationPrizesOptions() {
            return this.allConsolationDetail
                .filter(item => item.physicalPrize === false)
                .map(item => item.consolationName);
        },
        isWinnersFormFilled() {
            return (
                (this.error.ticket_number === '' || this.error.ticket_number == undefined) &&
                (this.error.position === '' || this.error.position == undefined)
            )
        },
        minDate() {
            return new Date().toISOString().split('T')[0];
        },
        formattedRaflleExpDate() {
            if (this.selectedRaffleDate && this.selectedRaffleTime) {
                const date = new Date(this.selectedRaffleDate + 'T' + this.selectedRaffleTime + ':00.000Z');
                return date.toISOString();
            }
            return null;
        },
        formattedDrawDate() {
            if (this.selectedDrawDate && this.selectedDrawTime) {
                const date = new Date(this.selectedDrawDate + 'T' + this.selectedDrawTime + ':00.000Z');
                return date.toISOString();
            }
            return null;
        },

        isFormFilled() {
            return (
                (this.error.raffle_code === '' || this.error.raffle_code === undefined) &&
                (this.error.quantity_of_items === '' || this.error.quantity_of_items === undefined) &&
                (this.error.main_image_url === '' || this.error.main_image_url === undefined) &&
                (this.error.summary === '' || this.error.summary === undefined) &&
                (this.error.description === '' || this.error.description === undefined) &&
                (this.error.reserve_price === '' || this.error.reserve_price === undefined) &&
                (this.error.no_of_tickets === '' || this.error.no_of_tickets === undefined) &&
                (this.error.raffle_length === '' || this.error.raffle_length === undefined) &&
                (this.error.raffle_expire === '' || this.error.raffle_expire === undefined) &&
                (this.error.raffle_added === '' || this.error.raffle_added === undefined) &&
                (this.error.featured === '' || this.error.featured === undefined) &&
                (this.error.tickets_sold === '' || this.error.tickets_sold === undefined) &&
                (this.error.tickets_left === '' || this.error.tickets_left === undefined) &&
                (this.error.ticket_start_int === '' || this.error.ticket_start_int === undefined) &&
                (this.error.category_id === '' || this.error.category_id === undefined) &&
                (this.error.status_id === '' || this.error.status_id === undefined) &&
                (this.error.charity_amount === '' || this.error.charity_amount === undefined) &&
                (this.error.title === '' || this.error.title === undefined) &&
                (this.error.product_source === '' || this.error.product_source === undefined) &&
                (this.error.retail_price === '' || this.error.retail_price === undefined) &&
                (this.error.specification === '' || this.error.specification === undefined) &&
                (this.error.target_price === '' || this.error.target_price === undefined) &&
                (this.error.mini_image_url === '' || this.error.mini_image_url === undefined) &&
                (this.error.facebook_description === '' || this.error.facebook_description === undefined) &&
                (this.error.facebook_title === '' || this.error.facebook_title === undefined) &&
                (this.error.credits_perTicket === '' || this.error.credits_perTicket === undefined) &&
                (this.error.extended === '' || this.error.extended === undefined) &&
                (this.error.extended_days === '' || this.error.extended_days === undefined) &&
                (this.error.is_cross_selling === '' || this.error.is_cross_selling === undefined) &&
                (this.error.is_credit_accepted === '' || this.error.is_credit_accepted === undefined) &&
                (this.error.is_discount_accepted === '' || this.error.is_discount_accepted === undefined) &&
                (this.error.tickets_per_entry === '' || this.error.tickets_per_entry === undefined) &&
                (this.error.max_entries === '' || this.error.max_entries === undefined) &&
                (this.error.is_charity === '' || this.error.is_charity === undefined) &&
                (this.error.charity_color_override === '' || this.error.charity_color_override === undefined) &&
                (this.error.is_flash === '' || this.error.is_flash === undefined) &&
                (this.error.slug === '' || this.error.slug === undefined) &&
                (this.error.video_url_mp4 === '' || this.error.video_url_mp4 === undefined) &&
                (this.error.video_length_mp4 === '' || this.error.video_length_mp4 === undefined) &&
                (this.error.video_url_webm === '' || this.error.video_url_webm === undefined) &&
                (this.error.no_winners === '' || this.error.no_winners === undefined) &&
                (this.error.jira_key_id === '' || this.error.jira_key_id === undefined) &&
                (this.error.main_image_alt === '' || this.error.main_image_alt === undefined) &&
                (this.error.mini_image_alt === '' || this.error.mini_image_alt === undefined) &&
                (this.error.promo_image_alt === '' || this.error.promo_image_alt === undefined) &&

                (this.error.width === '' || this.error.width === undefined) &&
                (this.error.details === '' || this.error.details === undefined) &&
                (this.error.CompetitionDetails === '' || this.error.CompetitionDetails === undefined) &&
                (this.error.packages_count === '' || this.error.packages_count === undefined) &&
                (this.error.productLength === '' || this.error.productLength === undefined) &&
                (this.error.height === '' || this.error.height === undefined) &&
                (this.error.weight === '' || this.error.weight === undefined) &&

                (this.error.promo_image_url === '' || this.error.promo_image_url === undefined) &&
                (this.error.drawing_in === '' || this.error.drawing_in === undefined) &&
                (this.error.postal_tickets === '' || this.error.postal_tickets === undefined) &&
                (this.error.ticket_entries === '' || this.error.ticket_entries === undefined) &&
                (this.error.is_unlimited_tickets === '' || this.error.is_unlimited_tickets === undefined) &&
                (this.error.tickets === '' || this.error.tickets === undefined) &&
                (this.error.is_live_draw === '' || this.error.is_live_draw === undefined) &&
                (this.error.is_sequential_tickets === '' || this.error.is_sequential_tickets === undefined) &&
                (this.error.is_auto_clone === '' || this.error.is_auto_clone === undefined) &&
                (this.error.auto_cloned === '' || this.error.auto_cloned === undefined) &&
                (this.error.cloned_from_id === '' || this.error.cloned_from_id === undefined) &&
                (this.error.video_no_sound === '' || this.error.video_no_sound === undefined) &&
                (this.error.video_live_drawurl === '' || this.error.video_live_drawurl === undefined) &&
                (this.error.meta_description === '' || this.error.meta_description === undefined) &&
                (this.error.meta_title === '' || this.error.meta_title === undefined) &&
                (this.error.xl_image_alt === '' || this.error.xl_image_alt === undefined) &&
                (this.error.xl_image_url === '' || this.error.xl_image_url === undefined) &&
                (this.error.is_free_raffle === '' || this.error.is_free_raffle === undefined) &&
                (this.error.is_new === '' || this.error.is_new === undefined) &&
                (this.error.charity_target_raised_amount === '' || this.error.charity_target_raised_amount === undefined) &&
                (this.error.charity_approximate_donation === '' || this.error.charity_approximate_donation === undefined) &&
                (this.error.is_unlimited_entries === '' || this.error.is_unlimited_entries === undefined) &&
                (this.error.is_guaranteed_draw === '' || this.error.is_guaranteed_draw === undefined) &&
                (this.error.autoraffle === '' || this.error.autoraffle === undefined) &&
                (this.error.displayed_winner_description === '' || this.error.displayed_winner_description === undefined) &&
                (this.error.displayed_winner_img_url === '' || this.error.displayed_winner_img_url === undefined) &&
                (this.error.entry_cost_gbp === '' || this.error.entry_cost_gbp === undefined) &&
                (this.error.is_unlimited_raffle === '' || this.error.is_unlimited_raffle === undefined) &&
                (this.error.total_entries === '' || this.error.total_entries === undefined) &&
                (this.error.total_entries_per_player === '' || this.error.total_entries_per_player === undefined) &&
                (this.error.discounted_entry_cost_gbp === '' || this.error.discounted_entry_cost_gbp === undefined) &&
                (this.error.discounted_entry_percentage === '' || this.error.discounted_entry_percentage === undefined) &&
                (this.error.disable_ticket_selector === '' || this.error.disable_ticket_selector === undefined) &&
                (this.error.youtube_link === '' || this.error.youtube_link === undefined) &&
                (this.error.hide_tickets_checkout === '' || this.error.hide_tickets_checkout === undefined) &&
                (this.error.instant_win_image === '' || this.error.instant_win_image === undefined) &&
                (this.error.no_of_top_prizes === '' || this.error.no_of_top_prizes === undefined) &&
                (this.error.cash_alternative === '' || this.error.cash_alternative === undefined) &&
                (this.error.claim_category === '' || this.error.claim_category === undefined) &&
                (this.error.super_raffle_img_url === '' || this.error.super_raffle_img_url === undefined) &&
                (this.error.publish === '' || this.error.publish === undefined) &&
                (this.error.state === '' || this.error.state === undefined) &&
                (this.error.raffle_type === '' || this.error.raffle_type === undefined) &&
                (this.error.market_value === '' || this.error.market_value === undefined) &&
                (this.error.year === '' || this.error.year === undefined) &&
                (this.error.ref_no === '' || this.error.ref_no === undefined) &&
                (this.error.prize_id === '' || this.error.prize_id === undefined) &&
                (this.error.box_papers === '' || this.error.box_papers === undefined) &&
                (this.error.discountsData === '' || this.error.discountsData === undefined) &&
                (this.error.consolation_prize_id === '' || this.error.consolation_prize_id === undefined)

            );
        },
    },
    watch: {
        selectedFiles: function () {
            this.uploadedImages = [];
        },

        'editItem.is_free_raffle': function (_newVal, _oldVal) {
            this.updateDiscountButtonVisibility();
        },
        'editItem.total_entries': function (_newVal, _oldVal) {
            let checkTotalEntries = this.validateTotalEntry('Total Enteries Per Player');
            if (checkTotalEntries) {
                this.error.total_entries_per_player = checkTotalEntries.error
            };
        },
        'editItem.total_entries_per_player': function (_newVal, _oldVal) {
            let checkEntries = this.validateTotalEntry('Discont Enteries');
            if (checkEntries) {
                this.error.entries = checkEntries.error
            }
        },
        'editItem.no_winners': function (_newVal, _oldVal) {
            let checkWinnEntries = this.validateTotalEntry('No Winners');
            if (checkWinnEntries) {
                this.error.no_winners = checkWinnEntries.error
            }
        },
        'editItem.is_cross_selling': function (_newVal, _oldVal) {
            if (!JSON.parse(this.editItem.is_cross_selling)) {
                this.error.cross_selling_main_img = '';
            }
        },

    },
    created() {
        if (Array.isArray(this.editItem.discounts)) {
            let parsedDiscountData = this.editItem.discounts.map((item) => ({
                serial_no: this.generateSecureSerialNumber(),
                entries: item.entries,
                discount: item.discount,
                mostPopular: item.mostPopular
            }));
            sessionStorage.setItem('discountDetails', JSON.stringify(parsedDiscountData))
        } else {
            sessionStorage.setItem('discountDetails', [])
        }

        if (Array.isArray(this.editItem.tickets)) {
            let parsedWinnersData = this.editItem.tickets.map((item) => ({
                serial_no: this.generateSecureSerialNumber(),
                ticket_number: item.ticket_number,
                position: item.position,
            }));
            sessionStorage.setItem('winnersDetails', JSON.stringify(parsedWinnersData))
        } else {
            sessionStorage.setItem('winnersDetails', [])
        }
    },
    methods: {
        ...mapActions([
            'id_name_option_detail',
            'seller_option_detail',
            'condition_option_detail',
            'sponsor_option_detail',
            'custom_raffle_option_detail',
            'charity_option_detail',
            'delivery_option_detail',
            'consolation_option_detail',
            'fetch_website_status',
            'question_detail'
        ]),
        route_to_drawPage(itemId) {
            let Id = itemId.split('#')[1];
            this.$router.push({
                name: 'preLiveDraw',
                params: {
                    id: Id
                }
            });
        },
        openDatePicker(refName) {
            const dateInput = this.$refs[refName];
            if (dateInput.showPicker) {
                dateInput.showPicker();
            } else {
                dateInput.focus();
            }
        },
        openTimePicker(refName) {
            const timeInput = this.$refs[refName];
            if (timeInput.showPicker) {
                timeInput.showPicker();
            } else {
                timeInput.focus();
            }
        },
        show_snackbar() {
            this.showSnackbar = true;
            setTimeout(() => {
                this.showSnackbar = false;
            }, 1000);
        },
        updateDrawButtonVisibility() {
            this.showDrawWinnerButton = ((this.editItem.is_live_draw === true || this.editItem.is_live_draw === "true") && (!this.editItem.tickets || this.editItem.tickets.length === 0) && !this.btnNameChange && this.editItem.status !== 'completed');
        },
        updateDiscountButtonVisibility() {
            this.showDiscountButton = ((this.editItem.is_free_raffle === false || this.editItem.is_free_raffle === "false") && this.editItem.status !== 'completed');
        },
        updateSaveButtonVisibility() {
            this.showSaveButton = (!this.btnNameChange && this.editItem.status !== 'completed');
        },
        calculateTicketsLeft() {
            if (Number(this.editItem.total_entries) >= Number(this.editItem.tickets_sold)) {
                this.editItem.tickets_left = Number(this.editItem.total_entries) - Number(this.editItem.tickets_sold);
                this.remove_error('tickets_sold');
            } else {
                this.error.tickets_sold = "Ticket sold is greater than Total entries"
            }
        },
        deleteThumbnailConfirm() {
            this.editItem.thumbnails.splice(this.thumbnailDltIndex, 1);
            this.dialogForDelete = false;
            this.thumbnailDelete = false;

        },
        deleteThumbnail(index) {
            this.thumbnailDelete = true;
            this.dialogForDelete = true;
            this.thumbnailDltIndex = index;

        },
        toggleSelection(index) {
            const thumbnails = this.editItem.thumbnails;
            for (let i = 0; i < thumbnails.length; i++) {
                if (i === index) {
                    thumbnails[i].upload = !thumbnails[i].upload;
                } else {
                    thumbnails[i].upload = false;
                }
            }
        },
        handle_img_upload(value) {
            switch (value) {
                case 'main_image_url':
                    this.mainImageObj = {
                        item: this.mainImageValue[0].name,
                        upload: true
                    }
                    this.error.main_image_url = '';
                    break;
                case 'mini_image_url':
                    this.miniImageObj = {
                        item: this.miniImageValue[0].name,
                        upload: true
                    }
                    this.error.mini_image_url = '';
                    break;
                case 'promo_image_url':
                    this.promoImageObj = {
                        item: this.promoImageValue[0].name,
                        upload: true
                    }
                    this.error.promo_image_url = '';
                    break;
                case 'cross_selling_main_img':
                    this.CrossSellingMainImageObj = {
                        item: this.CrossSellingMainImageValue[0].name,
                        upload: true
                    }
                    this.error.cross_selling_main_img = '';
                    break;
                case 'thumbnail':
                    this.thumbnailImageObj = this.thumbnailImageValue.map(file => ({
                        item: file.name,
                        upload: true
                    }));
                    this.error.thumbnail = '';
                    break;
            }
        },

        img_upload(value) {
            switch (value) {
                case 'main_image_url':
                    this.mainImgUpload = !this.mainImgUpload;
                    this.error.main_image_url = '';
                    break;
                case 'mini_image_url':
                    this.miniImgUpload = !this.miniImgUpload;
                    this.error.mini_image_url = '';
                    break;
                case 'promo_image_url':
                    this.promoImgUpload = !this.promoImgUpload;
                    this.error.promo_image_url = '';
                    break;
                case 'thumbnail':
                    this.thumbnailImgUpload = !this.thumbnailImgUpload;
                    this.error.thumbnail = '';
                    break;
                case 'CrossSellingMainImage':
                    this.CrossSellingImageUpload = !this.CrossSellingImageUpload;
                    this.error.cross_selling_main_img = '';
                    break;
            }
        },

        delete_batch_item() {
            switch (this.deleteDetails) {
                case 'Discount Details':
                    this.delete_discount_item(this.deleteId);
                    break;
                case 'winner Details':
                    this.delete_winner_item(this.deleteId);
                    break;
                default:
                    this.delete_discount_item(this.deleteId);
                    break;
            }
        },
        delete_discount(id) {
            if (this.discountDetails.serial_no != id) {
                this.dialogForDelete = true;
                this.deleteId = id;
                this.deleteDetails = 'Discount Details'
            }
        },
        delete_winner(id) {
            this.dialogForDelete = true;
            this.deleteId = id;
            this.deleteDetails = 'winner Details'
        },
        close_selected_delete() {
            this.dialogForDelete = false;
            this.deleteId = null;
            this.deleteDetails = null;
        },
        removeSelectedImages() {
            this.uploadedImages = this.uploadedImages.filter(image => !image.selected);
        },
        update_discount_item(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('discountDetails'));
            if (existingData && Array.isArray(existingData)) {
                const indexToUpdate = existingData.findIndex(item => item.serial_no === Id);
                if (indexToUpdate !== -1) {
                    existingData[indexToUpdate] = this.discountDetails;
                    sessionStorage.setItem('discountDetails', JSON.stringify(existingData));
                    this.editDiscountButton = false;
                    this.openDiscountsPage = false;
                    this.discountsData = existingData;
                    this.discountDetails = {
                        serial_no: '',
                        entries: '',
                        discount: '',
                        mostPopular: false
                    }
                }
            }
        },
        update_winner_item(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('winnersDetails'));
            if (existingData && Array.isArray(existingData)) {
                const indexToUpdate = existingData.findIndex(item => item.serial_no === Id);
                if (indexToUpdate !== -1) {
                    existingData[indexToUpdate] = this.winnersDetails;
                    sessionStorage.setItem('winnersDetails', JSON.stringify(existingData));
                    this.editWinnerButton = false;
                    this.openWinnersPage = false;
                    this.winnersData = existingData;
                    this.winnersDetails = {
                        serial_no: '',
                        ticket_number: '',
                        position: '',
                    }
                }
            }
        },
        delete_discount_item(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('discountDetails'));
            if (existingData && Array.isArray(existingData)) {
                const indexToRemove = existingData.findIndex(item => item.serial_no === Id);
                if (indexToRemove !== -1) {
                    existingData.splice(indexToRemove, 1);
                    sessionStorage.setItem('discountDetails', JSON.stringify(existingData));
                    this.discountsData = existingData
                }
            }
            this.dialogForDelete = false;
        },
        delete_winner_item(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('winnersDetails'));
            if (existingData && Array.isArray(existingData)) {
                const indexToRemove = existingData.findIndex(item => item.serial_no === Id);
                if (indexToRemove !== -1) {
                    existingData.splice(indexToRemove, 1);

                    sessionStorage.setItem('winnersDetails', JSON.stringify(existingData));
                    this.winnersData = existingData
                }
            }
            this.dialogForDelete = false;
        },
        edit_discount_item(Id) {
            this.error.discount = ""
            this.error.entries = ""
            this.error.mostPopular = ""

            this.editDiscountButton = true;
            let editResult = this.discountsData.find(item => item.serial_no === Id)
            this.discountDetails = editResult;
            this.openDiscountsPage = true;

        },
        edit_winner_item(Id) {
            this.editWinnerButton = true;
            let editResult = this.winnersData.find(item => item.serial_no === Id)
            this.winnersDetails = editResult;
            this.openWinnersPage = true;
        },
        validateTotalEntry(value) {
            let Total = this.editItem.total_entries ? this.editItem.total_entries : 0;
            let TotalPerPlayer = this.editItem.total_entries_per_player ? this.editItem.total_entries_per_player : 0;
            if (value === 'Total Enteries Per Player') {
                if (Number(this.editItem.total_entries_per_player) > Number(Total)) {
                    return {
                        valid: false,
                        error: `Total Entries Per Player Is More Than Total Entries ${Total}`
                    };
                } else {
                    return {
                        valid: true,
                        error: ""
                    };
                }
            } else if (value === 'No Winners') {
                if (Number(this.editItem.no_winners) > Number(Total)) {
                    return {
                        valid: false,
                        error: `Number of Winners Is More Than Total Entries ${Total}`
                    };
                } else {
                    return {
                        valid: true,
                        error: ""
                    };
                }
            } else {
                if (Number(this.discountDetails.entries) > Number(TotalPerPlayer)) {
                    return {
                        valid: false,
                        error: `Entries Is More Than Total Entries Per Player ${TotalPerPlayer}`
                    };
                } else {
                    return {
                        valid: true,
                        error: ""
                    };
                }
            }
        },
        save_discount_details() {
            this.discountDetails.serial_no = this.generateSecureSerialNumber();
            let existingData = JSON.parse(sessionStorage.getItem('discountDetails')) || [];
            existingData.push(this.discountDetails);
            sessionStorage.setItem('discountDetails', JSON.stringify(existingData));

            this.discountsData = existingData;
            this.discountDetails = {
                serial_no: '',
                entries: '',
                discount: '',
                mostPopular: false
            };
            this.openDiscountsPage = false;
        },
        save_winner_details() {
            this.winnersDetails.serial_no = this.generateSecureSerialNumber();
            let existingData = sessionStorage.getItem('winnersDetails');
            if (existingData) {
                existingData = JSON.parse(existingData);
            } else {
                existingData = [];
            }
            existingData.push(this.winnersDetails);
            sessionStorage.setItem('winnersDetails', JSON.stringify(existingData));
            this.winnersData = existingData;
            if (this.winnersData.length > 0) {
                this.winnerDraw = false
            }
            this.winnersDetails = {
                serial_no: '',
                ticket_number: '',
                position: '',
            }
            this.openWinnersPage = false
        },
        open_discount_Inputs() {
            this.openDiscountsPage = !this.openDiscountsPage;

            this.error.discount = "";
            this.error.entries = "";
            this.error.mostPopular = "";

            this.discountDetails = {
                serial_no: '',
                entries: '',
                discount: '',
                mostPopular: false
            };
        },
        open_winners_Inputs() {
            this.openWinnersPage = !this.openWinnersPage;
            this.error.ticket_number = "";
            this.error.position = "";

            this.discountDetails = {
                serial_no: '',
                ticket_number: '',
                position: '',
            };
        },
        validate_discount_inputs(Id) {
            if (this.discountsData.length) {
                let existingData = JSON.parse(sessionStorage.getItem('discountDetails')) || [];
                if (!this.editDiscountButton) {
                    let itemExist = existingData.find(item => item.entries == this.discountDetails.entries)
                    if (itemExist) this.error.entries = "Duplicate Entries";
                } else {
                    let itemExist = existingData.find(item => item.entries == this.discountDetails.entries && item.serial_no != this.discountDetails.serial_no)
                    if (itemExist) this.error.entries = "Duplicate Entries";
                }
            }
            if (this.discountsData.length) {
                let existingData = JSON.parse(sessionStorage.getItem('discountDetails')) || [];
                if (!this.editDiscountButton) {
                    let itemExist = existingData.find(item => item.discount == this.discountDetails.discount)
                    if (itemExist) this.error.discount = "Duplicate Discount";
                } else {
                    let itemExist = existingData.find(item => item.discount == this.discountDetails.discount && item.serial_no != this.discountDetails.serial_no)
                    if (itemExist) this.error.discount = "Duplicate Discount";
                }
            }

            if (this.discountsData.length) {
                let existingData = JSON.parse(sessionStorage.getItem('discountDetails')) || [];
                let mostPopularCount = existingData.filter(item => item.mostPopular === "true").length;
                if (!this.editDiscountButton) {
                    if (this.discountDetails.mostPopular === "true" && mostPopularCount >= 1) {
                        this.error.mostPopular = "Only one discount can be marked as 'Most Popular'";
                    } else {
                        this.error.mostPopular = '';
                    }
                } else {
                    let isCurrentMostPopular = existingData.find(item => item.mostPopular === "true" && item.serial_no != this.discountDetails.serial_no);
                    if (this.discountDetails.mostPopular === "true" && isCurrentMostPopular) {
                        this.error.mostPopular = "Only one discount can be marked as 'Most Popular'";
                    } else {
                        this.error.mostPopular = '';
                    }
                }
            }

            this.error.discountsData = "";
            let discount = this.valid_inputs(this.discountDetails.discount);
            let entries = this.valid_inputs(this.discountDetails.entries);
            let mostPopular = this.valid_inputs(this.discountDetails.mostPopular);

            if (!entries.valid) {
                this.error.entries = entries.error;
            } else if (!discount.valid) {
                this.error.discount = discount.error;
            } else if (!mostPopular.valid) {
                this.error.mostPopular = mostPopular.error;
            } else {
                if (this.isDiscountsFormFilled) {
                    this.error.discount = "";
                    this.error.entries = "";
                    this.error.mostPopular = "";

                    this.error.discountsData = "";
                    if (!this.editDiscountButton) {
                        this.save_discount_details();
                    } else {
                        this.update_discount_item(Id);
                    }
                }
            }
        },
        validate_winners_inputs(Id) {
            if (this.winnersData.length) {
                let existingData = JSON.parse(sessionStorage.getItem('winnersDetails')) || [];
                if (!this.editWinnerButton) {
                    let itemExist = existingData.find(item => item.ticket_number == this.winnersDetails.ticket_number)
                    if (itemExist) this.error.ticket_number = "Duplicate Ticket Number";
                } else {
                    let itemExist = existingData.find(item => item.ticket_number == this.winnersDetails.ticket_number && item.serial_no != this.winnersDetails.serial_no)
                    if (itemExist) this.error.ticket_number = "Duplicate Ticket Number";
                }
            }
            if (this.winnersData.length) {
                let existingData = JSON.parse(sessionStorage.getItem('winnersDetails')) || [];
                let itemExist = existingData.find(item => item.position == this.winnersDetails.position && item.serial_no != this.winnersDetails.serial_no)
                if (itemExist) this.error.position = "Duplicate Position";
            }

            let ticket_number = this.valid_inputs(this.winnersDetails.ticket_number);
            let position = this.valid_inputs(this.winnersDetails.position);
            if (!ticket_number.valid) {
                this.error.ticket_number = ticket_number.error;
            } else if (!position.valid) {
                this.error.position = position.error;
            } else {
                if (this.isWinnersFormFilled) {
                    if (!this.editWinnerButton) {
                        this.save_winner_details();
                    } else {
                        this.update_winner_item(Id);
                    }
                }
            }
        },
        async load_option_data() {
            const apiEndpoint = `${apiUrl.fetchRaffoluxCategory}`;
            let optionKey = "SortedCategory"
            await this.$store.dispatch('id_name_option_detail', {
                currentURL: apiEndpoint,
                optionsKey: optionKey
            });
        },
        handleUserChange() {
            let selectedUser = this.allIdNameDetail.find(item => item.allNames === this.categoryName);
            if (selectedUser) {
                this.editItem.category_id = selectedUser.allId;
                this.categoryName = selectedUser.allNames;
                this.remove_error('category_id');
            }
        },
        async load_question_data() {
            const apiEndpoint = apiUrl.fetchQuestion;
            await this.$store.dispatch('question_detail', apiEndpoint);
        },
        handle_questionItem_name() {
            let selectedUser = this.allQuestionOptionDetail.find(item => item.question === this.question);
            if (selectedUser) {
                this.editItem.question_id = selectedUser.questionId;
                this.question = selectedUser.question;
                this.remove_error('question_id');
            }
        },
        async load_consolation_data() {
            const apiEndpoint = `${apiUrl.fetchPrize}`;
            await this.$store.dispatch('consolation_option_detail', apiEndpoint);
        },
        handleConsolationChange() {
            let selectedUser = this.allConsolationDetail.find(item => item.consolationName === this.consolationName);
            if (selectedUser) {
                this.editItem.consolation_prize_id = selectedUser.consolationID;
                this.consolationName = selectedUser.consolationName;
                this.remove_error('consolation_prize_id');
            }
        },
        handlePrizeChange() {
            let selectedUser = this.allConsolationDetail.find(item => item.prizeName === this.prizeName);
            if (selectedUser) {
                this.editItem.prize_id = selectedUser.consolationID;
                this.prizeName = selectedUser.prizeName;
                this.remove_error('prize_id');
            }
        },
        remove_error(value) {
            switch (value) {
                case 'raffle_code':
                    let raffle_code_numeric = this.validateNumberField(this.editItem.raffle_code, 'Raffle code');
                    if (!raffle_code_numeric.valid) {
                        this.error.raffle_code = raffle_code_numeric.error;
                        break;
                    } else {
                        this.error.raffle_code = '';
                        break;
                    }
                    case 'quantity_of_items':
                        let qantity_of_items_numeric = this.validateNumberField(this.editItem.quantity_of_items, 'Quantity of Items');
                        if (!qantity_of_items_numeric.valid) {
                            this.error.quantity_of_items = qantity_of_items_numeric.error;
                            break;
                        } else {
                            this.error.quantity_of_items = '';
                            break;
                        }
                        case 'main_image_url':
                            this.error.main_image_url = '';
                            break;
                        case 'summary':
                            this.error.summary = '';
                            break;
                        case 'description':
                            this.error.description = '';
                            break;
                        case 'reserve_price':
                            let reserve_price_numeric = this.validateNumberField(this.editItem.reserve_price, 'Reserve Price');
                            if (!reserve_price_numeric.valid) {
                                this.error.reserve_price = reserve_price_numeric.error;
                                break;
                            } else {
                                this.error.reserve_price = '';
                                break;
                            }
                            case 'no_of_tickets':
                                let no_of_tickets_numeric = this.validateNumberField(this.editItem.no_of_tickets, 'Number of Tickets');
                                if (!no_of_tickets_numeric.valid) {
                                    this.error.no_of_tickets = no_of_tickets_numeric.error;
                                    break;
                                } else {
                                    this.error.no_of_tickets = '';
                                    break;
                                }
                                case 'raffle_length':
                                    let raffle_length_numeric = this.validateNumberField(this.editItem.raffle_length, 'Raffle Length');
                                    if (!raffle_length_numeric.valid) {
                                        this.error.raffle_length = raffle_length_numeric.error;
                                        break;
                                    } else {
                                        this.error.raffle_length = '';
                                        break;
                                    }
                                    case 'raffle_expire':
                                        this.error.raffle_expire = '';
                                        break;
                                    case 'raffle_added':
                                        this.error.raffle_added = '';
                                        break;
                                    case 'featured':
                                        this.error.featured = '';
                                        break;
                                    case 'tickets_sold':
                                        let tickets_sold_numeric = this.validateNumberField(this.editItem.tickets_sold, 'Tickets Sold');
                                        if (!tickets_sold_numeric.valid) {
                                            this.error.tickets_sold = tickets_sold_numeric.error;
                                            break;
                                        } else {
                                            this.error.tickets_sold = '';
                                            break;
                                        }
                                        case 'tickets_left':
                                            let tickets_left_numeric = this.validateNumberField(this.editItem.tickets_left, 'Tickets Left');
                                            if (!tickets_left_numeric.valid) {
                                                this.error.tickets_left = tickets_left_numeric.error;
                                                break;
                                            } else {
                                                this.error.tickets_left = '';
                                                break;
                                            }
                                            case 'ticket_start_int':
                                                let ticket_start_int_numeric = this.validateNumberField(this.editItem.ticket_start_int, 'Ticket Start Integer');
                                                if (!ticket_start_int_numeric.valid) {
                                                    this.error.ticket_start_int = ticket_start_int_numeric.error;
                                                    break;
                                                } else {
                                                    this.error.ticket_start_int = '';
                                                    break;
                                                }
                                                case 'discount':
                                                    let discount = this.validate_non_zero_num(this.discountDetails.discount, 'Discount')
                                                    if (!discount.valid) {
                                                        this.error.discount = discount.error;
                                                        break;
                                                    } else {
                                                        if (this.discountDetails.discount > 100) {
                                                            this.error.discount = 'Discount cannot exceed 100%.';
                                                            break;
                                                        }
                                                        this.error.discount = '';
                                                        break;
                                                    }
                                                    case 'entries':
                                                        let entries = this.validate_non_zero_num(this.discountDetails.entries, 'Entries')
                                                        if (!entries.valid) {
                                                            this.error.entries = entries.error;
                                                            break;
                                                        } else {
                                                            let checkEnteries = this.validateTotalEntry('Discont Enteries')
                                                            if (!checkEnteries.valid) {
                                                                this.error.entries = checkEnteries.error;
                                                                break;
                                                            } else {
                                                                this.error.entries = '';
                                                                break;
                                                            }
                                                        }
                                                        case 'ticket_number':
                                                            let ticket_number = this.validateNumberField(this.winnersDetails.ticket_number, 'Ticket number');
                                                            if (!ticket_number.valid) {
                                                                this.error.ticket_number = ticket_number.error;
                                                                break;
                                                            } else {
                                                                this.error.ticket_number = '';
                                                                break;
                                                            }
                                                            case 'position':
                                                                let position = this.validateNumberField(this.winnersDetails.position, 'position');
                                                                if (!position.valid) {
                                                                    this.error.position = position.error;
                                                                    break;
                                                                } else {
                                                                    this.error.position = '';
                                                                    break;
                                                                }
                                                                case 'img_title':
                                                                    this.error.img_title = '';
                                                                    break;
                                                                case 'img_url':
                                                                    this.error.img_url = '';
                                                                    break;
                                                                case 'rafflecode_id':
                                                                    this.error.rafflecode_id = '';
                                                                    break;

                                                                case 'category_id':
                                                                    this.error.category_id = '';
                                                                    break;
                                                                case 'question_id':
                                                                    this.error.question_id = '';
                                                                    break;

                                                                case 'status_id':
                                                                    this.error.status_id = '';
                                                                    break;

                                                                case 'charity_amount':
                                                                    let charity_amount_numeric = this.validateNumberField(this.editItem.charity_amount, 'Charity Amount');
                                                                    if (!charity_amount_numeric.valid) {
                                                                        this.error.charity_amount = charity_amount_numeric.error;
                                                                        break;
                                                                    } else {
                                                                        this.error.charity_amount = '';
                                                                        break;
                                                                    }
                                                                    case 'title':
                                                                        this.error.title = '';
                                                                        break;
                                                                    case 'product_source':
                                                                        this.error.product_source = '';
                                                                        break;
                                                                    case 'retail_price':
                                                                        let retail_price_numeric = this.validateNumberField(this.editItem.retail_price, 'Retail Price');
                                                                        if (!retail_price_numeric.valid) {
                                                                            this.error.retail_price = retail_price_numeric.error;
                                                                            break;
                                                                        } else {
                                                                            this.error.retail_price = '';
                                                                            break;
                                                                        }
                                                                        case 'specification':
                                                                            this.error.specification = '';
                                                                            break;
                                                                        case 'target_price':
                                                                            let target_price_numeric = this.validateNumberField(this.editItem.target_price, 'Target Price');
                                                                            if (!target_price_numeric.valid) {
                                                                                this.error.target_price = target_price_numeric.error;
                                                                                break;
                                                                            } else {
                                                                                this.error.target_price = '';
                                                                                break;
                                                                            }

                                                                            case 'mini_image_url':
                                                                                this.error.mini_image_url = '';
                                                                                break;
                                                                            case 'facebook_description':
                                                                                this.error.facebook_description = '';
                                                                                break;
                                                                            case 'facebook_title':
                                                                                this.error.facebook_title = '';
                                                                                break;
                                                                            case 'credits_perTicket':
                                                                                let credits_perTicket_numeric = this.validateNumberField(this.editItem.credits_perTicket, 'Credits per Ticket');
                                                                                if (!credits_perTicket_numeric.valid) {
                                                                                    this.error.credits_perTicket = credits_perTicket_numeric.error;
                                                                                    break;
                                                                                } else {
                                                                                    this.error.credits_perTicket = '';
                                                                                    break;
                                                                                }
                                                                                case 'extended_days':
                                                                                    let extended_days_numeric = this.validateNumberField(this.editItem.extended_days, 'Extended Days');
                                                                                    if (!extended_days_numeric.valid) {
                                                                                        this.error.extended_days = extended_days_numeric.error;
                                                                                        break;
                                                                                    } else {
                                                                                        this.error.extended_days = '';
                                                                                        break;
                                                                                    }

                                                                                    case 'is_cross_selling':
                                                                                        this.error.is_cross_selling = '';
                                                                                        break;
                                                                                    case 'is_credit_accepted':
                                                                                        this.error.is_credit_accepted = '';
                                                                                        break;
                                                                                    case 'is_discount_accepted':
                                                                                        this.error.is_discount_accepted = '';
                                                                                        break;
                                                                                    case 'tickets_per_entry':
                                                                                        let tickets_per_entry_numeric = this.validateNumberField(this.editItem.tickets_per_entry, 'Tickets Per Entry');
                                                                                        if (!tickets_per_entry_numeric.valid) {
                                                                                            this.error.tickets_per_entry = tickets_per_entry_numeric.error;
                                                                                            break;
                                                                                        } else {
                                                                                            this.error.tickets_per_entry = '';
                                                                                            break;
                                                                                        }
                                                                                        case 'max_entries':
                                                                                            let max_entries_numeric = this.validateNumberField(this.editItem.max_entries, 'Max Entries');
                                                                                            if (!max_entries_numeric.valid) {
                                                                                                this.error.max_entries = max_entries_numeric.error;
                                                                                                break;
                                                                                            } else {
                                                                                                this.error.max_entries = '';
                                                                                                break;
                                                                                            }
                                                                                            case 'is_charity':
                                                                                                this.error.is_charity = '';
                                                                                                break;
                                                                                            case 'charity_color_override':
                                                                                                this.error.charity_color_override = '';
                                                                                                break;
                                                                                            case 'slug':
                                                                                                this.error.slug = '';
                                                                                                break;
                                                                                            case 'video_url_mp4':
                                                                                                this.error.video_url_mp4 = '';
                                                                                                break;

                                                                                            case 'video_length_mp4':
                                                                                                let video_length_mp4_numeric = this.validateNumberField(this.editItem.video_length_mp4, 'Video Length MP4');
                                                                                                if (!video_length_mp4_numeric.valid) {
                                                                                                    this.error.video_length_mp4 = video_length_mp4_numeric.error;
                                                                                                    break;
                                                                                                } else {
                                                                                                    this.error.video_length_mp4 = '';
                                                                                                    break;
                                                                                                }
                                                                                                case 'video_url_webm':
                                                                                                    this.error.video_url_webm = '';
                                                                                                    break;
                                                                                                case 'no_winners':
                                                                                                    let no_winners_numeric = this.validateNumberField(this.editItem.no_winners, 'Number of Winners');
                                                                                                    if (!no_winners_numeric.valid) {
                                                                                                        this.error.no_winners = no_winners_numeric.error;
                                                                                                        break;
                                                                                                    } else {
                                                                                                        let checkTotalEntries = this.validateTotalEntry('No Winners');
                                                                                                        if (!checkTotalEntries.valid) return this.error.no_winners = checkTotalEntries.error;
                                                                                                        this.error.no_winners = '';
                                                                                                        break;
                                                                                                    }
                                                                                                    case 'jira_key_id':
                                                                                                        this.error.jira_key_id = '';
                                                                                                        break;

                                                                                                    case 'main_image_alt':
                                                                                                        this.error.main_image_alt = '';
                                                                                                        break;
                                                                                                    case 'mini_image_alt':
                                                                                                        this.error.mini_image_alt = '';
                                                                                                        break;
                                                                                                    case 'promo_image_alt':
                                                                                                        this.error.promo_image_alt = '';
                                                                                                        break;
                                                                                                    case 'promo_image_url':
                                                                                                        this.error.promo_image_url = '';
                                                                                                        break;
                                                                                                    case 'width':
                                                                                                        this.error.width = '';
                                                                                                        break;
                                                                                                    case 'height':
                                                                                                        this.error.height = '';
                                                                                                        break;
                                                                                                    case 'productLength':
                                                                                                        this.error.productLength = '';
                                                                                                        break;
                                                                                                    case 'weight':
                                                                                                        this.error.weight = '';
                                                                                                        break;
                                                                                                    case 'packages_count':
                                                                                                        this.error.packages_count = '';
                                                                                                        break;
                                                                                                    case 'details':
                                                                                                        this.error.details = '';
                                                                                                        break;
                                                                                                    case 'CompetitionDetails':
                                                                                                        this.error.CompetitionDetails = '';
                                                                                                        break;

                                                                                                    case 'drawing_in':
                                                                                                        this.error.drawing_in = '';
                                                                                                        break;
                                                                                                    case 'postal_tickets':
                                                                                                        this.error.postal_tickets = '';
                                                                                                        break;
                                                                                                    case 'ticket_entries':
                                                                                                        let ticket_entries_numeric = this.validateNumberField(this.editItem.ticket_entries, 'Ticket entries');
                                                                                                        if (!ticket_entries_numeric.valid) {
                                                                                                            this.error.ticket_entries = ticket_entries_numeric.error;
                                                                                                            break;
                                                                                                        } else {
                                                                                                            this.error.ticket_entries = '';
                                                                                                            break;
                                                                                                        }
                                                                                                        case 'tickets':
                                                                                                            this.error.tickets = '';
                                                                                                            break;
                                                                                                        case 'is_live_draw':
                                                                                                            this.error.is_live_draw = '';
                                                                                                            break;
                                                                                                        case 'cloned_from_id':
                                                                                                            let cloned_from_id_numeric = this.validateNumberField(this.editItem.cloned_from_id, 'Cloned From ID');
                                                                                                            if (!cloned_from_id_numeric.valid) {
                                                                                                                this.error.cloned_from_id = cloned_from_id_numeric.error;
                                                                                                                break;
                                                                                                            } else {
                                                                                                                this.error.cloned_from_id = '';
                                                                                                                break;
                                                                                                            }
                                                                                                            case 'video_no_sound':
                                                                                                                this.error.video_no_sound = '';
                                                                                                                break;
                                                                                                            case 'video_live_drawurl':
                                                                                                                this.error.video_live_drawurl = '';
                                                                                                                break;
                                                                                                            case 'meta_description':
                                                                                                                this.error.meta_description = '';
                                                                                                                break;
                                                                                                            case 'meta_title':
                                                                                                                this.error.meta_title = '';
                                                                                                                break;
                                                                                                            case 'xl_image_alt':
                                                                                                                this.error.xl_image_alt = '';
                                                                                                                break;
                                                                                                            case 'xl_image_url':
                                                                                                                this.error.xl_image_url = '';
                                                                                                                break;
                                                                                                            case 'is_free_raffle':
                                                                                                                this.error.is_free_raffle = '';
                                                                                                                break;
                                                                                                            case 'charity_target_raised_amount':
                                                                                                                let charity_target_raised_amount_numeric = this.validateNumberField(this.editItem.charity_target_raised_amount, 'Charity Target Raised Amount');
                                                                                                                if (!charity_target_raised_amount_numeric.valid) {
                                                                                                                    this.error.charity_target_raised_amount = charity_target_raised_amount_numeric.error;
                                                                                                                    break;
                                                                                                                } else {
                                                                                                                    this.error.charity_target_raised_amount = '';
                                                                                                                    break;
                                                                                                                }
                                                                                                                case 'charity_approximate_donation':
                                                                                                                    let charity_approximate_donation_numeric = this.validateNumberField(this.editItem.charity_approximate_donation, 'Charity Approximate Donation');
                                                                                                                    if (!charity_approximate_donation_numeric.valid) {
                                                                                                                        this.error.charity_approximate_donation = charity_approximate_donation_numeric.error;
                                                                                                                        break;
                                                                                                                    } else {
                                                                                                                        this.error.charity_approximate_donation = '';
                                                                                                                        break;
                                                                                                                    }
                                                                                                                    case 'is_unlimited_entries':
                                                                                                                        this.error.is_unlimited_entries = '';
                                                                                                                        break;
                                                                                                                    case 'displayed_winner_description':
                                                                                                                        this.error.displayed_winner_description = '';
                                                                                                                        break;
                                                                                                                    case 'displayed_winner_img_url':
                                                                                                                        this.error.displayed_winner_img_url = '';
                                                                                                                        break;

                                                                                                                    case 'entry_cost_gbp':
                                                                                                                        let entry_cost_gbp_numeric;
                                                                                                                        if (JSON.parse(this.editItem.is_free_raffle)) {
                                                                                                                            entry_cost_gbp_numeric = this.validateNumberField(this.editItem.entry_cost_gbp, 'Entry Cost GBP');

                                                                                                                        } else {
                                                                                                                            entry_cost_gbp_numeric = this.validate_non_zero_num(this.editItem.entry_cost_gbp, 'Entry Cost GBP');
                                                                                                                        }
                                                                                                                        if (!entry_cost_gbp_numeric.valid) {
                                                                                                                            this.error.entry_cost_gbp = entry_cost_gbp_numeric.error;
                                                                                                                            break;
                                                                                                                        } else {
                                                                                                                            this.error.entry_cost_gbp = '';
                                                                                                                            break;
                                                                                                                        }
                                                                                                                        case 'total_entries':
                                                                                                                            let total_entries_numeric = this.validate_non_zero_num(this.editItem.total_entries, 'Total Entries');
                                                                                                                            if (!total_entries_numeric.valid) {
                                                                                                                                this.error.total_entries = total_entries_numeric.error;
                                                                                                                                break;
                                                                                                                            } else {
                                                                                                                                this.error.total_entries = '';
                                                                                                                                break;
                                                                                                                            }
                                                                                                                            case 'total_entries_per_player':
                                                                                                                                let total_entries_per_player_numeric = this.validateNumberField(this.editItem.total_entries_per_player, 'Total Entries per Player');
                                                                                                                                if (!total_entries_per_player_numeric.valid) {
                                                                                                                                    this.error.total_entries_per_player = total_entries_per_player_numeric.error;
                                                                                                                                    break;
                                                                                                                                } else {
                                                                                                                                    let checkTotalEntries = this.validateTotalEntry('Total Enteries Per Player');
                                                                                                                                    if (!checkTotalEntries.valid) return this.error.total_entries_per_player = checkTotalEntries.error;
                                                                                                                                    this.error.total_entries_per_player = '';
                                                                                                                                    break;
                                                                                                                                }
                                                                                                                                case 'discounted_entry_cost_gbp':
                                                                                                                                    let discounted_entry_cost_gbp_numeric = this.validateNumberField(this.editItem.discounted_entry_cost_gbp, 'Discounted Entry Cost GBP');
                                                                                                                                    if (!discounted_entry_cost_gbp_numeric.valid) {
                                                                                                                                        this.error.discounted_entry_cost_gbp = discounted_entry_cost_gbp_numeric.error;
                                                                                                                                        break;
                                                                                                                                    } else {
                                                                                                                                        this.error.discounted_entry_cost_gbp = '';
                                                                                                                                        break;
                                                                                                                                    }
                                                                                                                                    case 'discounted_entry_percentage':
                                                                                                                                        let discounted_entry_percentage_numeric = this.validateNumberField(this.editItem.discounted_entry_percentage, 'Discounted Entry Percentage');
                                                                                                                                        if (!discounted_entry_percentage_numeric.valid) {
                                                                                                                                            this.error.discounted_entry_percentage = discounted_entry_percentage_numeric.error;
                                                                                                                                            break;
                                                                                                                                        } else {
                                                                                                                                            this.error.discounted_entry_percentage = '';
                                                                                                                                            break;
                                                                                                                                        }
                                                                                                                                        case 'youtube_link':
                                                                                                                                            this.error.youtube_link = '';
                                                                                                                                            break;
                                                                                                                                        case 'instant_win_image':
                                                                                                                                            this.error.instant_win_image = '';
                                                                                                                                            break;
                                                                                                                                        case 'no_of_top_prizes':
                                                                                                                                            let no_of_top_prizes_numeric = this.validateNumberField(this.editItem.no_of_top_prizes, 'No of top prizes');
                                                                                                                                            if (!no_of_top_prizes_numeric.valid) {
                                                                                                                                                this.error.no_of_top_prizes = no_of_top_prizes_numeric.error;
                                                                                                                                                break;
                                                                                                                                            } else {
                                                                                                                                                this.error.no_of_top_prizes = '';
                                                                                                                                                break;
                                                                                                                                            }
                                                                                                                                            case 'cash_alternative':
                                                                                                                                                let cash_alternative_numeric = this.validateNumberField(this.editItem.cash_alternative, 'Cash Alternative');
                                                                                                                                                if (!cash_alternative_numeric.valid) {
                                                                                                                                                    this.error.cash_alternative = cash_alternative_numeric.error;
                                                                                                                                                    break;
                                                                                                                                                } else {
                                                                                                                                                    this.error.cash_alternative = '';
                                                                                                                                                    break;
                                                                                                                                                }
                                                                                                                                                case 'claim_category':
                                                                                                                                                    let claim_category_numeric = this.validateNumberField(this.editItem.claim_category, 'Claim Category');
                                                                                                                                                    if (!claim_category_numeric.valid) {
                                                                                                                                                        this.error.claim_category = claim_category_numeric.error;
                                                                                                                                                        break;
                                                                                                                                                    } else {
                                                                                                                                                        this.error.claim_category = '';
                                                                                                                                                        break;
                                                                                                                                                    }
                                                                                                                                                    case 'super_raffle_img_url':
                                                                                                                                                        this.error.super_raffle_img_url = '';
                                                                                                                                                        break;
                                                                                                                                                    case 'publish':
                                                                                                                                                        this.error.publish = '';
                                                                                                                                                        break;
                                                                                                                                                    case 'mostPopular':
                                                                                                                                                        this.error.mostPopular = '';
                                                                                                                                                        break;
                                                                                                                                                    case 'state':
                                                                                                                                                        let state_numeric = this.validateNumberField(this.editItem.state, 'State');
                                                                                                                                                        if (!state_numeric.valid) {
                                                                                                                                                            this.error.state = state_numeric.error;
                                                                                                                                                            break;
                                                                                                                                                        } else {
                                                                                                                                                            this.error.state = '';
                                                                                                                                                            break;
                                                                                                                                                        }
                                                                                                                                                        case 'raffle_type':
                                                                                                                                                            this.error.raffle_type = '';
                                                                                                                                                            break;
                                                                                                                                                        case 'market_value':
                                                                                                                                                            let market_value_numeric = this.validateNumberField(this.editItem.market_value, 'Market Value');
                                                                                                                                                            if (!market_value_numeric.valid) {
                                                                                                                                                                this.error.market_value = market_value_numeric.error;
                                                                                                                                                                break;
                                                                                                                                                            } else {
                                                                                                                                                                this.error.market_value = '';
                                                                                                                                                                break;
                                                                                                                                                            }
                                                                                                                                                            case 'year':
                                                                                                                                                                let year = this.validateYear(this.editItem.year);
                                                                                                                                                                if (!year.valid) {
                                                                                                                                                                    this.error.year = year.error;
                                                                                                                                                                    break;
                                                                                                                                                                } else {
                                                                                                                                                                    this.error.year = '';
                                                                                                                                                                    break;
                                                                                                                                                                }
                                                                                                                                                                case 'ref_no':
                                                                                                                                                                    let ref_no = this.validate_name(this.editItem.ref_no, 'Reference Number');
                                                                                                                                                                    if (!ref_no.valid) {
                                                                                                                                                                        this.error.ref_no = ref_no.error;
                                                                                                                                                                        break;
                                                                                                                                                                    } else {
                                                                                                                                                                        this.error.ref_no = '';
                                                                                                                                                                        break;
                                                                                                                                                                    }
                                                                                                                                                                    case 'box_papers':
                                                                                                                                                                        this.error.box_papers = '';
                                                                                                                                                                        break;
                                                                                                                                                                    case 'consolation_prize_id':
                                                                                                                                                                        this.error.consolation_prize_id = '';
                                                                                                                                                                        break;
                                                                                                                                                                    case 'prize_id':
                                                                                                                                                                        this.error.prize_id = '';
                                                                                                                                                                        break;

                                                                                                                                                                    case 'api':
                                                                                                                                                                        this.error.api = '';
                                                                                                                                                                        break;
                                                                                                                                                                    default:
                                                                                                                                                                        this.error = {
                                                                                                                                                                            raffle_code: '',
                                                                                                                                                                            quantity_of_items: '',
                                                                                                                                                                            main_image_url: '',
                                                                                                                                                                            summary: '',
                                                                                                                                                                            description: '',
                                                                                                                                                                            reserve_price: '',
                                                                                                                                                                            no_of_tickets: '',
                                                                                                                                                                            raffle_length: '',
                                                                                                                                                                            raffle_expire: '',
                                                                                                                                                                            raffle_added: '',
                                                                                                                                                                            featured: '',
                                                                                                                                                                            tickets_sold: '',
                                                                                                                                                                            tickets_left: '',
                                                                                                                                                                            ticket_start_int: '',
                                                                                                                                                                            category_id: '',
                                                                                                                                                                            question_id: '',
                                                                                                                                                                            condition_id: '',
                                                                                                                                                                            delivery_information_id: '',
                                                                                                                                                                            status_id: '',
                                                                                                                                                                            charity_id: '',
                                                                                                                                                                            charity_amount: '',
                                                                                                                                                                            title: '',
                                                                                                                                                                            product_source: '',
                                                                                                                                                                            retail_price: '',
                                                                                                                                                                            specification: '',
                                                                                                                                                                            target_price: '',
                                                                                                                                                                            seller_id: '',
                                                                                                                                                                            mini_image_url: '',
                                                                                                                                                                            facebook_description: '',
                                                                                                                                                                            facebook_title: '',
                                                                                                                                                                            credits_perTicket: '',
                                                                                                                                                                            extended: '',
                                                                                                                                                                            extended_days: '',
                                                                                                                                                                            is_cross_selling: '',
                                                                                                                                                                            is_credit_accepted: '',
                                                                                                                                                                            is_discount_accepted: '',
                                                                                                                                                                            tickets_per_entry: '',
                                                                                                                                                                            max_entries: '',
                                                                                                                                                                            is_charity: '',
                                                                                                                                                                            charity_color_override: '',
                                                                                                                                                                            is_flash: '',
                                                                                                                                                                            slug: '',
                                                                                                                                                                            video_url_mp4: '',
                                                                                                                                                                            video_length_mp4: '',
                                                                                                                                                                            video_url_webm: '',
                                                                                                                                                                            no_winners: '',
                                                                                                                                                                            jira_key_id: '',
                                                                                                                                                                            sponsor_id: '',
                                                                                                                                                                            main_image_alt: '',
                                                                                                                                                                            mini_image_alt: '',
                                                                                                                                                                            promo_image_alt: '',
                                                                                                                                                                            promo_image_url: '',
                                                                                                                                                                            drawing_in: '',
                                                                                                                                                                            postal_tickets: '',
                                                                                                                                                                            ticket_entries: '',
                                                                                                                                                                            is_unlimited_tickets: '',
                                                                                                                                                                            tickets: '',
                                                                                                                                                                            is_live_draw: '',
                                                                                                                                                                            is_sequential_tickets: '',
                                                                                                                                                                            is_auto_clone: '',
                                                                                                                                                                            auto_cloned: '',
                                                                                                                                                                            cloned_from_id: '',
                                                                                                                                                                            video_no_sound: '',
                                                                                                                                                                            video_live_drawurl: '',
                                                                                                                                                                            meta_description: '',
                                                                                                                                                                            meta_title: '',
                                                                                                                                                                            xl_image_alt: '',
                                                                                                                                                                            xl_image_url: '',
                                                                                                                                                                            is_free_raffle: '',
                                                                                                                                                                            is_new: '',
                                                                                                                                                                            charity_target_raised_amount: '',
                                                                                                                                                                            charity_approximate_donation: '',
                                                                                                                                                                            is_unlimited_entries: '',
                                                                                                                                                                            is_guaranteed_draw: '',
                                                                                                                                                                            autoraffle: '',
                                                                                                                                                                            custom_raffle_id: '',
                                                                                                                                                                            displayed_winner_description: '',
                                                                                                                                                                            displayed_winner_img_url: '',
                                                                                                                                                                            all_raffle_sort: '',
                                                                                                                                                                            entry_cost_gbp: '',
                                                                                                                                                                            is_unlimited_raffle: '',
                                                                                                                                                                            total_entries: '',
                                                                                                                                                                            total_entries_per_player: '',
                                                                                                                                                                            discounted_entry_cost_gbp: '',
                                                                                                                                                                            discounted_entry_percentage: '',
                                                                                                                                                                            disable_ticket_selector: '',
                                                                                                                                                                            youtube_link: '',
                                                                                                                                                                            hide_tickets_checkout: '',
                                                                                                                                                                            instant_win_image: '',
                                                                                                                                                                            no_of_top_prizes: '',
                                                                                                                                                                            cash_alternative: '',
                                                                                                                                                                            claim_category: '',
                                                                                                                                                                            super_raffle_img_url: '',
                                                                                                                                                                            publish: '',
                                                                                                                                                                            state: '',
                                                                                                                                                                            raffle_type: '',
                                                                                                                                                                            market_value: '',
                                                                                                                                                                            year: '',
                                                                                                                                                                            ref_no: '',
                                                                                                                                                                            box_papers: '',
                                                                                                                                                                            consolation_prize_id: '',
                                                                                                                                                                            prize_id: '',
                                                                                                                                                                            consolation_prize_value: '',
                                                                                                                                                                            discount: '',
                                                                                                                                                                            entries: '',
                                                                                                                                                                            mostPopular: '',
                                                                                                                                                                            position: '',
                                                                                                                                                                            ticket_number: '',
                                                                                                                                                                            img_title: '',
                                                                                                                                                                            img_url: '',
                                                                                                                                                                            rafflecode_id: '',
                                                                                                                                                                            thumbnails: '',
                                                                                                                                                                            discountsData: '',
                                                                                                                                                                            api: '',
                                                                                                                                                                            details: '',
                                                                                                                                                                            packages_count: '',
                                                                                                                                                                            width: '',
                                                                                                                                                                            productLength: '',
                                                                                                                                                                            height: '',
                                                                                                                                                                            weight: '',
                                                                                                                                                                            CompetitionDetails: '',
                                                                                                                                                                        }
            }
        },
        validate_inputs() {
            let summary = this.valid_inputs(this.editItem.summary);
            let description = this.valid_inputs(this.editItem.description);
            let tickets_sold = this.valid_inputs(this.editItem.tickets_sold);
            const categoryName = this.valid_inputs(this.categoryName)
            const question = this.valid_inputs(this.question)
            let title = this.valid_inputs(this.editItem.title);
            let retail_price = this.valid_inputs(this.editItem.retail_price);
            let specification = this.valid_inputs(this.editItem.specification);
            let slug = this.valid_inputs(this.editItem.slug);;
            let no_winners = this.valid_inputs(this.editItem.no_winners);
            let main_image_alt = this.valid_inputs(this.editItem.main_image_alt);
            let mini_image_alt = this.valid_inputs(this.editItem.mini_image_alt);

            let width = this.valid_inputs(this.editItem.width);
            let height = this.valid_inputs(this.editItem.height);
            let productLength = this.valid_inputs(this.editItem.productLength);
            let weight = this.valid_inputs(this.editItem.weight);
            let packages_count = this.valid_inputs(this.editItem.package);
            let details = this.valid_inputs(this.editItem.details);
            let CompetitionDetails = this.valid_inputs(this.editItem.CompetitionDetails);
            let promo_image_alt = this.valid_inputs(this.editItem.promo_image_alt);
            let is_live_draw = this.valid_inputs(this.editItem.is_live_draw);
            let is_unlimited_entries = this.valid_inputs(this.editItem.is_unlimited_entries);
            let entry_cost_gbp = this.valid_inputs(this.editItem.entry_cost_gbp);
            let total_entries = this.valid_inputs(this.editItem.total_entries);
            let total_entries_per_player = this.valid_inputs(this.editItem.total_entries_per_player);
            let cash_alternative = this.valid_inputs(this.editItem.cash_alternative);
            let market_value = this.valid_inputs(this.editItem.market_value);
            let year = this.valid_inputs(this.editItem.year);
            let ref_no = this.valid_inputs(this.editItem.ref_no);
            let box_papers = this.valid_inputs(this.editItem.box_papers);
            let consolationName = this.valid_inputs(this.consolationName)
            let prizeName = this.valid_inputs(this.prizeName);
            let atLeastOneMostPopular = this.discountsData.some(item => item.mostPopular === "true");

            let discountsData;
            if (this.editItem.is_free_raffle === "false" || this.editItem.is_free_raffle === false) {
                discountsData = this.valid_inputs(this.discountsData)
            } else {
                discountsData = {
                    valid: true,
                    error: ""
                }
            }

            let main_image_url;
            if (!this.btnNameChange) {
                if (this.mainImgUpload) {
                    main_image_url = this.valid_inputs(this.mainImageValue);
                } else {
                    main_image_url = {
                        valid: true,
                        error: ""
                    };
                }
            } else {
                main_image_url = this.valid_inputs(this.mainImageValue);
            }

            let mini_image_url;
            if (!this.btnNameChange) {
                if (this.miniImgUpload) {
                    mini_image_url = this.valid_inputs(this.miniImageValue);
                } else {
                    mini_image_url = {
                        valid: true,
                        error: ""
                    };
                }
            } else {
                mini_image_url = this.valid_inputs(this.miniImageValue);
            }
            let promo_image_url;

            if (!this.btnNameChange) {
                if (this.promoImgUpload) {
                    promo_image_url = this.valid_inputs(this.promoImageValue);
                } else {
                    promo_image_url = {
                        valid: true,
                        error: ""
                    };
                }
            } else {
                promo_image_url = this.valid_inputs(this.promoImageValue);
            }
            let cross_selling_main_img;
            if (JSON.parse(this.editItem.is_cross_selling)) {
                if (!this.btnNameChange) {
                    if (this.CrossSellingImageUpload) {
                        cross_selling_main_img = this.valid_inputs(this.CrossSellingMainImageValue);
                    } else {
                        cross_selling_main_img = {
                            valid: true,
                            error: ""
                        };
                    }
                } else {
                    cross_selling_main_img = this.valid_inputs(this.CrossSellingMainImageValue);
                }
            } else {
                cross_selling_main_img = {
                    valid: true,
                    error: ""
                };
            }
            if (!title.valid) {
                this.error.title = title.error;
                this.$refs.titleField.$el.querySelector('input').focus();
                return;
            } else if (!categoryName.valid) {
                this.error.category_id = categoryName.error;
                this.$refs.categoryNameField.$el.querySelector('input').focus();
                return;
            } else if (!total_entries.valid) {
                this.error.total_entries = total_entries.error;
                this.$refs.totalEntriesField.$el.querySelector('input').focus();
                return;
            } else if (!retail_price.valid) {
                this.error.retail_price = retail_price.error;
                this.$refs.retailPriceField.$el.querySelector('input').focus();
                return;
            } else if (!tickets_sold.valid) {
                this.error.tickets_sold = tickets_sold.error;
                this.$refs.ticketsSoldField.$el.querySelector('input').focus();
                return;
            } else if (!entry_cost_gbp.valid) {
                this.error.entry_cost_gbp = entry_cost_gbp.error;
                this.$refs.entryCostGbpField.$el.querySelector('input').focus();
                return;
            } else if (!slug.valid) {
                this.error.slug = slug.error;
                this.$refs.slugField.$el.querySelector('input').focus();
                return;
            } else if (!specification.valid) {
                this.error.specification = specification.error;
                this.$refs.specificationField.$el.querySelector('textarea').focus();
                return;
            } else if (!summary.valid) {
                this.error.summary = summary.error;
                this.$refs.summaryField.$el.querySelector('textarea').focus();
                return;
            } else if (!description.valid) {
                this.error.description = description.error;
                this.$refs.descriptionField.$el.querySelector('textarea').focus();
                return;
            } else if (!market_value.valid) {
                this.error.market_value = market_value.error;
                this.$refs.marketValueField.$el.querySelector('input').focus();
                return;
            } else if (!year.valid) {
                this.error.year = year.error;
                this.$refs.yearField.$el.querySelector('input').focus();
                return;
            } else if (!ref_no.valid) {
                this.error.ref_no = ref_no.error;
                this.$refs.refNoField.$el.querySelector('input').focus();
                return;
            } else if (!box_papers.valid) {
                this.error.box_papers = box_papers.error;
                this.$refs.boxPapersField.$el.querySelector('input').focus();
                return;
            } else if (!prizeName.valid) {
                this.error.prize_id = prizeName.error;
                this.$refs.prizeNameField.$el.querySelector('input').focus();
                return;
            } else if (!consolationName.valid) {
                this.error.consolation_prize_id = consolationName.error;
                this.$refs.consolationNameField.$el.querySelector('input').focus();
                return;
            } else if (!cash_alternative.valid) {
                this.error.cash_alternative = cash_alternative.error;
                this.$refs.cashAlternativeField.$el.querySelector('input').focus();
                return;
            } else if (!no_winners.valid) {
                this.error.no_winners = no_winners.error;
                this.$refs.noWinnersField.$el.querySelector('input').focus();
                return;
            } else if (!is_live_draw.valid) {
                this.error.is_live_draw = is_live_draw.error;
                this.$refs.isLiveDrawField.$el.querySelector('input').focus();
                return;
            } else if (!is_unlimited_entries.valid) {
                this.error.is_unlimited_entries = is_unlimited_entries.error;
                this.$refs.isUnlimitedEntriesField.$el.querySelector('input').focus();
                return;
            } else if (!total_entries_per_player.valid) {
                this.error.total_entries_per_player = total_entries_per_player.error;
                this.$refs.totalEntriesPerPlayerField.$el.querySelector('input').focus();
                return;
            } else if (!question.valid) {
                this.error.question_id = question.error;
                this.$refs.questionField.$el.querySelector('input').focus();
                return;
            } else if (!mini_image_alt.valid) {
                this.error.mini_image_alt = mini_image_alt.error;
                this.$refs.miniImageAltField.$el.querySelector('input').focus();
                return;
            } else if (!main_image_alt.valid) {
                this.error.main_image_alt = main_image_alt.error;
                this.$refs.mainImageAltField.$el.querySelector('input').focus();
                return;
            } else if (!promo_image_alt.valid) {
                this.error.promo_image_alt = promo_image_alt.error;
                this.$refs.promoImageAltField.$el.querySelector('input').focus();
                return;
            } else if (!width.valid) {
                this.error.width = width.error;
                this.$refs.widthField.$el.querySelector('input').focus();
                return;
            } else if (!height.valid) {
                this.error.height = height.error;
                this.$refs.heightField.$el.querySelector('input').focus();
                return;
            } else if (!productLength.valid) {
                this.error.productLength = productLength.error;
                this.$refs.lengthField.$el.querySelector('input').focus();
                return;
            } else if (!weight.valid) {
                this.error.weight = weight.error;
                this.$refs.weightField.$el.querySelector('input').focus();
                return;
            } else if (!packages_count.valid) {
                this.error.packages_count = packages_count.error;
                this.$refs.packageField.$el.querySelector('input').focus();
                return;
            } else if (!details.valid) {
                this.error.details = details.error;
                this.$refs.promoImageAltField.$el.querySelector('input').focus();
                return;
            } else if (!CompetitionDetails.valid) {
                this.error.CompetitionDetails = CompetitionDetails.error;
                this.$refs.competitionDetailsField.$el.querySelector('input').focus();
                return;
            } else if (!main_image_url.valid) {
                this.error.main_image_url = main_image_url.error;
                this.$refs.mainImageUrlField.$el.querySelector('input').focus();
                return;
            } else if (!mini_image_url.valid) {
                this.error.mini_image_url = mini_image_url.error;
                this.$refs.miniImageUrlField.$el.querySelector('input').focus();
                return;
            } else if (!promo_image_url.valid) {
                this.error.promo_image_url = promo_image_url.error;
                this.$refs.promoImageUrlField.$el.querySelector('input').focus();
                return;
            } else if (!cross_selling_main_img.valid) {
                this.error.cross_selling_main_img = cross_selling_main_img.error;
                this.$refs.CrossSellingImageField.$el.querySelector('input').focus();
                return;
            } else if (!discountsData.valid || this.discountsData.length !== 3) {
                this.error.discountsData = "Please add 3 Discounts";
                return;
            } else if (!atLeastOneMostPopular) {
                this.error.discountsData = "At least one discount must be marked as 'Most Popular'";
            } else {
                let valid = this.validateExpireAndDraw();
                if (!valid) {
                    this.error.api = "Drawing-In must be equal to the Raffle Expiry Date and Time."
                } else {
                    let free = this.free_raffle();
                    if (!free) return;
                    if (this.isFormFilled) {
                        this.error = {
                            raffle_code: '',
                            quantity_of_items: '',
                            main_image_url: '',
                            summary: '',
                            description: '',
                            reserve_price: '',
                            no_of_tickets: '',
                            raffle_length: '',
                            raffle_expire: '',
                            raffle_added: '',
                            featured: '',
                            tickets_sold: '',
                            tickets_left: '',
                            ticket_start_int: '',
                            category_id: '',
                            question_id: '',
                            condition_id: '',
                            delivery_information_id: '',
                            status_id: '',
                            charity_id: '',
                            charity_amount: '',
                            title: '',
                            product_source: '',
                            retail_price: '',
                            specification: '',
                            target_price: '',
                            seller_id: '',
                            mini_image_url: '',
                            facebook_description: '',
                            facebook_title: '',
                            credits_perTicket: '',
                            extended: '',
                            extended_days: '',
                            is_cross_selling: '',
                            is_credit_accepted: '',
                            is_discount_accepted: '',
                            tickets_per_entry: '',
                            max_entries: '',
                            is_charity: '',
                            charity_color_override: '',
                            is_flash: '',
                            slug: '',
                            video_url_mp4: '',
                            video_length_mp4: '',
                            video_url_webm: '',
                            no_winners: '',
                            jira_key_id: '',
                            sponsor_id: '',
                            main_image_alt: '',
                            mini_image_alt: '',
                            promo_image_alt: '',
                            promo_image_url: '',
                            drawing_in: '',
                            postal_tickets: '',
                            ticket_entries: '',
                            is_unlimited_tickets: '',
                            tickets: '',
                            is_live_draw: '',
                            is_sequential_tickets: '',
                            is_auto_clone: '',
                            auto_cloned: '',
                            cloned_from_id: '',
                            video_no_sound: '',
                            video_live_drawurl: '',
                            meta_description: '',
                            meta_title: '',
                            xl_image_alt: '',
                            xl_image_url: '',
                            is_free_raffle: '',
                            is_new: '',
                            charity_target_raised_amount: '',
                            charity_approximate_donation: '',
                            is_unlimited_entries: '',
                            is_guaranteed_draw: '',
                            autoraffle: '',
                            custom_raffle_id: '',
                            displayed_winner_description: '',
                            displayed_winner_img_url: '',
                            all_raffle_sort: '',
                            entry_cost_gbp: '',
                            is_unlimited_raffle: '',
                            total_entries: '',
                            total_entries_per_player: '',
                            discounted_entry_cost_gbp: '',
                            discounted_entry_percentage: '',
                            disable_ticket_selector: '',
                            youtube_link: '',
                            hide_tickets_checkout: '',
                            instant_win_image: '',
                            no_of_top_prizes: '',
                            cash_alternative: '',
                            claim_category: '',
                            super_raffle_img_url: '',
                            publish: '',
                            state: '',
                            raffle_type: '',
                            market_value: '',
                            year: '',
                            ref_no: '',
                            box_papers: '',
                            discount: '',
                            entries: '',
                            position: '',
                            ticket_number: '',
                            consolation_prize_id: '',
                            consolation_prize_value: '',
                            discountsData: '',
                            api: '',
                            details: '',
                            packages_count: '',
                            width: '',
                            productLength: '',
                            height: '',
                            weight: '',
                            CompetitionDetails: '',
                        };
                        if (this.btnNameChange) {
                            this.add_website_raffle_table_data()
                        } else {

                            let savewinner = this.save_winner();
                            if (!savewinner) {
                                this.error.winnerDraw = "Please draw winner before saving"
                                return;
                            } else {
                                this.edit_website_raffle_table_data()
                            }
                        }
                    }
                }
            }
        },
        validateExpireAndDraw() {
            const drawingInDate = new Date(this.formattedDrawDate);
            const raffleExpireDate = new Date(this.formattedRaflleExpDate);
            return drawingInDate !== raffleExpireDate;
        },
        save_winner() {
            if (this.winnersData.length === 0) {
                return true
            } else if (this.winnersData.length > 0 && this.winnerDraw) {
                return true
            } else {
                return false
            }
        },
        free_raffle() {
            let entry_cost_gbp_numeric;
            if (JSON.parse(this.editItem.is_free_raffle)) {
                entry_cost_gbp_numeric = this.validateNumberField(this.editItem.entry_cost_gbp, 'Entry Cost GBP');

            } else {
                entry_cost_gbp_numeric = this.validate_non_zero_num(this.editItem.entry_cost_gbp, 'Entry Cost GBP');
            }
            if (!entry_cost_gbp_numeric.valid) {
                this.error.entry_cost_gbp = entry_cost_gbp_numeric.error;
                this.$refs.entryCostGbpField.$el.querySelector('input').focus();
                return false;
            } else {
                this.error.entry_cost_gbp = '';
                return true;
            }
        },
        convert_status() {
            switch (this.editItem.status) {
                case 'draft':
                    return 0;
                    break;
                case 'active':
                    return 1;
                    break;
                case 'inactive':
                    return 2;
                    break;
                case 'expired':
                    return 3;
                    break;
                case 'soldout':
                    return 4;
                    break;
                case 'completed':
                    return 5;
                    break;
                case 'drawing':
                    return 12;
                    break;
            }

        },
        async edit_website_raffle_table_data() {
            try {
                this.buttonDisabled = true;
                if (this.mainImageObj.upload) {
                    this.editItem.main_image_url = this.mainImageObj;
                }
                if (this.miniImageObj.upload) {
                    this.editItem.mini_image_url = this.miniImageObj;
                }
                if (this.promoImageObj.upload) {
                    this.editItem.promo_image_url = this.promoImageObj;
                }
                if (this.CrossSellingMainImageObj.upload) {
                    this.editItem.cross_selling_main_img = this.CrossSellingMainImageObj;
                }

                this.editItem.featured = JSON.parse(this.editItem.featured);
                this.editItem.is_cross_selling = JSON.parse(this.editItem.is_cross_selling);
                this.editItem.is_credit_accepted = JSON.parse(this.editItem.is_credit_accepted);
                this.editItem.is_discount_accepted = JSON.parse(this.editItem.is_discount_accepted);
                this.editItem.is_charity = JSON.parse(this.editItem.is_charity);
                this.editItem.is_live_draw = JSON.parse(this.editItem.is_live_draw);
                this.editItem.is_free_raffle = JSON.parse(this.editItem.is_free_raffle);
                this.editItem.is_unlimited_entries = JSON.parse(this.editItem.is_unlimited_entries);
                this.editItem.publish = JSON.parse(this.editItem.publish);
                this.editItem.box_papers = JSON.parse(this.editItem.box_papers);

                if (this.editItem.is_free_raffle) {
                    this.editItem.entry_cost_gbp = parseFloat(0).toFixed(2);
                } else {
                    this.editItem.entry_cost_gbp = parseFloat(this.editItem.entry_cost_gbp).toFixed(2);
                }
                let parsedDiscountData = this.discountsData.map((item) => ({
                    entries: item.entries,
                    discount: item.discount,
                    mostPopular: item.mostPopular,
                }));
                let parsedWinnersData = this.winnersData.map((item) => ({
                    ticket_number: item.ticket_number,
                    position: item.position,
                }));
                let thumbnailImageNames = this.editItem.thumbnails.map(thumbnail => ({
                    item: thumbnail.item,
                    newSignInKey: thumbnail.newSignInKey,
                    upload: false
                }));
                if (this.thumbnailImageObj.length) {
                    this.thumbnailImageObj.forEach(file => {
                        thumbnailImageNames.push({
                            item: file.item,
                            upload: true
                        });
                    });
                }
                const body = {
                    id: this.editItem.id,
                    sort_id: this.editItem.sort_id,
                    title: this.editItem.title,
                    category_id: (this.editItem.category_id),
                    question_id: (this.editItem.question_id),
                    raffle_code: Number(this.editItem.raffle_code),
                    total_entries: Number(this.editItem.total_entries),
                    retail_price: Number(this.editItem.retail_price),
                    status: this.convert_status(),
                    tickets_sold: Number(this.editItem.tickets_sold),
                    is_charity: (this.editItem.is_charity),
                    publish: (this.editItem.publish),
                    is_cross_selling: (this.editItem.is_cross_selling),
                    is_credit_accepted: (this.editItem.is_credit_accepted),
                    is_discount_accepted: (this.editItem.is_discount_accepted),
                    entry_cost_gbp: this.editItem.entry_cost_gbp,
                    slug: this.editItem.slug,
                    specification: this.editItem.specification,
                    drawing_in: this.formattedDrawDate,
                    summary: this.editItem.summary,
                    description: this.editItem.description,
                    raffle_expire: this.formattedRaflleExpDate,
                    featured: (this.editItem.featured),
                    market_value: Number(this.editItem.market_value),
                    year: Number(this.editItem.year),
                    ref_no: (this.editItem.ref_no),
                    box_papers: (this.editItem.box_papers),
                    prize_id: (this.editItem.prize_id),
                    consolation_prize_id: (this.editItem.consolation_prize_id),
                    cash_alternative: Number(this.editItem.cash_alternative),
                    no_winners: Number(this.editItem.no_winners),
                    is_live_draw: (this.editItem.is_live_draw),
                    is_unlimited_entries: (this.editItem.is_unlimited_entries),
                    total_entries_per_player: Number(this.editItem.total_entries_per_player),
                    meta_description: this.editItem.meta_description,
                    meta_title: this.editItem.meta_title,
                    product_source: this.editItem.product_source,
                    is_free_raffle: (this.editItem.is_free_raffle),
                    tickets: parsedWinnersData,
                    main_image_alt: this.editItem.main_image_alt,
                    mini_image_alt: this.editItem.mini_image_alt,
                    width: this.editItem.width,
                    height: this.editItem.height,
                    productLength: this.editItem.productLength,
                    weight: this.editItem.weight,
                    package: this.editItem.package,
                    details: this.editItem.details,
                    CompetitionDetails: this.editItem.CompetitionDetails,
                    promo_image_alt: this.editItem.promo_image_alt,
                    discounts: JSON.parse(this.editItem.is_free_raffle) ? [] : parsedDiscountData,
                    thumbnails: thumbnailImageNames,
                    main_image_url: this.editItem.main_image_url,
                    promo_image_url: this.editItem.promo_image_url,
                    mini_image_url: this.editItem.mini_image_url,
                    cross_selling_main_img: this.editItem.cross_selling_main_img,
                };

                const jsonString = JSON.stringify(body);
                await fetch(apiUrl.updateRaffle, {
                        method: "PUT",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: jsonString
                    })
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            if (this.mainImageObj.upload || this.CrossSellingMainImageObj.upload || this.miniImageObj.upload || this.promoImageObj.upload || this.editItem.thumbnails.some(thumbnail => thumbnail.upload) || thumbnailImageNames.some(thumbnail => thumbnail.upload)) {
                                let mainSignedUrlObj;
                                let mainMessage;
                                let miniSignedUrlObj;
                                let miniMessage;
                                let promoSignedUrlObj;
                                let promoMessage;
                                let thumbnailMessages;
                                let CrossMessage;
                                let CrossSignedUrlObj;
                                let allUploadedSuccessfully;
                                let thumbnailSignedUrlObjs = [];
                                if (this.mainImageObj.upload) {
                                    mainSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.mainImageValue[0].name);
                                    mainMessage = await this.uploadToS3(mainSignedUrlObj.url, this.mainImageValue[0]);
                                }
                                if (this.miniImageObj.upload) {
                                    miniSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.miniImageValue[0].name);
                                    miniMessage = await this.uploadToS3(miniSignedUrlObj.url, this.miniImageValue[0]);
                                }
                                if (this.promoImageObj.upload) {
                                    promoSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.promoImageValue[0].name);
                                    promoMessage = await this.uploadToS3(promoSignedUrlObj.url, this.promoImageValue[0]);
                                }
                                if (this.CrossSellingMainImageObj.upload && this.CrossSellingMainImageValue.length > 0) {
                                    CrossSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.CrossSellingMainImageValue[0].name);
                                    CrossMessage = await this.uploadToS3(CrossSignedUrlObj.url, this.CrossSellingMainImageValue[0]);
                                }
                                if (this.thumbnailImageObj.length) {
                                    this.thumbnailImageValue.map(file => {
                                        let signedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == file.name);
                                        if (signedUrlObj) {
                                            thumbnailSignedUrlObjs.push(signedUrlObj);
                                        }
                                    });
                                    thumbnailMessages = await Promise.all(thumbnailSignedUrlObjs.map(async signedUrlObj => {

                                        let imageFile = this.thumbnailImageValue.find(file => file.name === signedUrlObj.file);
                                        if (imageFile) {
                                            let res = await this.uploadToS3(signedUrlObj.url, imageFile);
                                            return res;
                                        } else {
                                            throw new Error("Image file not found for signed URL object.");
                                        }
                                    }));

                                    allUploadedSuccessfully = thumbnailMessages.every(message => message === "File uploaded successfully");
                                }
                                if (
                                    mainMessage === "File uploaded successfully" ||
                                    miniMessage === "File uploaded successfully" ||
                                    promoMessage === "File uploaded successfully" ||
                                    CrossMessage === "File uploaded successfully" ||
                                    allUploadedSuccessfully
                                ) {
                                    this.handleSuccess("Successfully saved...");
                                } else {
                                    if (mainMessage !== "File uploaded successfully") {
                                        this.error.main_image_url = mainMessage;
                                    }
                                    if (miniMessage !== "File uploaded successfully") {

                                        this.error.mini_image_url = miniMessage;
                                    }
                                    if (promoMessage !== "File uploaded successfully") {
                                        this.error.promo_image_url = promoMessage;
                                    }
                                    if (CrossMessage !== "File uploaded successfully") {
                                        this.error.cross_selling_main_img = CrossMessage;
                                    }
                                    if (allUploadedSuccessfully) {
                                        this.error.thumbnail = thumbnailMessages;
                                    }
                                }
                            } else {
                                this.handleSuccess("Successfully saved...");
                            }
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.error.api = error;
            } finally {
                this.buttonDisabled = false;
            }
        },

        handleSuccess(message) {
            this.editItem.thumbnails.forEach(thumbnail => {
                thumbnail.upload = false;
            });
            this.successMessage = message;
            this.$emit("data-changed", this.successMessage);
        },
        async add_website_raffle_table_data() {
            try {
                this.buttonDisabled = true;
                this.editItem.featured = JSON.parse(this.editItem.featured);
                this.editItem.is_cross_selling = JSON.parse(this.editItem.is_cross_selling);
                this.editItem.is_credit_accepted = JSON.parse(this.editItem.is_credit_accepted);
                this.editItem.is_discount_accepted = JSON.parse(this.editItem.is_discount_accepted);
                this.editItem.is_charity = JSON.parse(this.editItem.is_charity);
                this.editItem.is_live_draw = JSON.parse(this.editItem.is_live_draw);
                this.editItem.is_free_raffle = JSON.parse(this.editItem.is_free_raffle);
                this.editItem.is_unlimited_entries = JSON.parse(this.editItem.is_unlimited_entries);
                this.editItem.publish = JSON.parse(this.editItem.publish);
                this.editItem.box_papers = JSON.parse(this.editItem.box_papers);
                if (this.editItem.is_free_raffle) {
                    this.editItem.entry_cost_gbp = parseFloat(0).toFixed(2);
                } else {
                    this.editItem.entry_cost_gbp = parseFloat(this.editItem.entry_cost_gbp).toFixed(2);
                }
                let parsedDiscountData = this.discountsData.map((item) => ({
                    entries: item.entries,
                    discount: item.discount,
                    mostPopular: item.mostPopular,
                }));
                if (this.CrossSellingMainImageObj.upload && this.CrossSellingMainImageValue.length > 0) {
                    this.editItem.cross_selling_main_img = this.CrossSellingMainImageObj;
                } else {
                    this.editItem.cross_selling_main_img = ''
                }
                const body = {
                    title: this.editItem.title,
                    category_id: (this.editItem.category_id),
                    question_id: (this.editItem.question_id),
                    raffle_code: Number(this.editItem.raffle_code),
                    total_entries: Number(this.editItem.total_entries),
                    retail_price: Number(this.editItem.retail_price),
                    status: this.convert_status(),
                    tickets_sold: Number(this.editItem.tickets_sold),
                    is_charity: (this.editItem.is_charity),
                    publish: (this.editItem.publish),
                    is_cross_selling: (this.editItem.is_cross_selling),
                    is_credit_accepted: (this.editItem.is_credit_accepted),
                    is_discount_accepted: (this.editItem.is_discount_accepted),
                    entry_cost_gbp: this.editItem.entry_cost_gbp,
                    slug: this.editItem.slug,
                    specification: this.editItem.specification,
                    drawing_in: this.formattedDrawDate,
                    summary: this.editItem.summary,
                    description: this.editItem.description,
                    raffle_expire: this.formattedRaflleExpDate,
                    featured: (this.editItem.featured),
                    market_value: Number(this.editItem.market_value),
                    year: Number(this.editItem.year),
                    ref_no: (this.editItem.ref_no),
                    box_papers: (this.editItem.box_papers),
                    prize_id: (this.editItem.prize_id),
                    consolation_prize_id: (this.editItem.consolation_prize_id),
                    cash_alternative: Number(this.editItem.cash_alternative),
                    no_winners: Number(this.editItem.no_winners),
                    is_live_draw: (this.editItem.is_live_draw),
                    is_unlimited_entries: (this.editItem.is_unlimited_entries),
                    total_entries_per_player: Number(this.editItem.total_entries_per_player),
                    meta_description: this.editItem.meta_description,
                    meta_title: this.editItem.meta_title,
                    product_source: this.editItem.product_source,
                    is_free_raffle: (this.editItem.is_free_raffle),
                    main_image_alt: this.editItem.main_image_alt,
                    mini_image_alt: this.editItem.mini_image_alt,

                    width: this.editItem.width,
                    height: this.editItem.height,
                    productLength: this.editItem.productLength,
                    weight: this.editItem.weight,
                    package: this.editItem.package,
                    details: this.editItem.details,
                    CompetitionDetails: this.editItem.CompetitionDetails,

                    promo_image_alt: this.editItem.promo_image_alt,
                    discounts: JSON.parse(this.editItem.is_free_raffle) ? [] : parsedDiscountData,
                    thumbnails: this.thumbnailImageObj,
                    main_image_url: this.mainImageObj,
                    mini_image_url: this.miniImageObj,
                    promo_image_url: this.promoImageObj,
                    cross_selling_main_img: this.editItem.cross_selling_main_img
                };
                const jsonString = JSON.stringify(body);
                await fetch(apiUrl.addRaffle, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: jsonString
                    })
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            let mainSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.mainImageValue[0].name);
                            let miniSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.miniImageValue[0].name);
                            let promoSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.promoImageValue[0].name);
                            let CrossSignedUrlObj
                            if (this.CrossSellingMainImageObj.upload && this.CrossSellingMainImageValue.length > 0) {
                                CrossSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.CrossSellingMainImageValue[0].name);
                            }
                            let thumbnailSignedUrlObjs = [];
                            this.thumbnailImageValue.map(file => {
                                let signedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == file.name);
                                if (signedUrlObj) {
                                    thumbnailSignedUrlObjs.push(signedUrlObj);
                                }
                            });
                            let CrossMessage;
                            if (CrossSignedUrlObj) {
                                CrossMessage = await this.uploadToS3(CrossSignedUrlObj.url, this.CrossSellingMainImageValue[0]);
                            } else {
                                CrossMessage = 'File uploaded successfully';
                            }
                            let mainMessage = await this.uploadToS3(mainSignedUrlObj.url, this.mainImageValue[0]);
                            let miniMessage = await this.uploadToS3(miniSignedUrlObj.url, this.miniImageValue[0]);
                            let promoMessage = await this.uploadToS3(promoSignedUrlObj.url, this.promoImageValue[0]);
                            let thumbnailMessages = await Promise.all(thumbnailSignedUrlObjs.map(async signedUrlObj => {
                                let imageFile = this.thumbnailImageValue.find(file => file.name === signedUrlObj.file);
                                if (imageFile) {
                                    let resImg = await this.uploadToS3(signedUrlObj.url, imageFile);
                                    return resImg
                                } else {
                                    throw new Error("Image file not found for signed URL object.");
                                }
                            }));

                            if (
                                mainMessage === "File uploaded successfully" &&
                                miniMessage === "File uploaded successfully" &&
                                promoMessage === "File uploaded successfully" &&
                                CrossMessage === 'File uploaded successfully' &&
                                thumbnailMessages.every(message => message === "File uploaded successfully")
                            ) {
                                this.successMessage = "Successfully added...";
                                this.$emit("data-changed", this.successMessage);
                            } else {
                                if (mainMessage !== "File uploaded successfully") {
                                    this.error.main_image_url = mainMessage;
                                }
                                if (miniMessage !== "File uploaded successfully") {
                                    this.error.mini_image_url = miniMessage;
                                }
                                if (promoMessage !== "File uploaded successfully") {
                                    this.error.promo_image_url = promoMessage;
                                }
                                if (CrossMessage !== 'File uploaded successfully') {
                                    this.error.cross_selling_main_img = CrossMessage;
                                }
                                thumbnailMessages.forEach((message, index) => {
                                    if (message !== "File uploaded successfully") {
                                        this.error.thumbnail[`image_${index + 1}`] = message;
                                    }
                                });
                            }
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });

            } catch (error) {
                this.error.api = error;
            } finally {
                this.buttonDisabled = false;
            }
        },
        async add_winner_data() {
            this.winnerbuttonDisabled = true;
            try {
                let parsedWinnersData = this.winnersData.map((item) => ({
                    ticket_number: Number(item.ticket_number),
                    position: Number(item.position),
                }));
                const body = {
                    raffleId: this.editItem.sort_id,
                    tickets: parsedWinnersData
                }
                const jsonString = JSON.stringify(body);
                await fetch(apiUrl.addWinnerDraw, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: jsonString
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.error.api = "";
                            this.successMessage = "Winners added successfully...";
                            this.show_snackbar();
                            this.editItem.status = "completed";
                            this.winnerDraw = true;
                            this.showDrawWinnerButton = false;
                        } else {
                            this.error.api = data.message ? data.message : data;
                            this.winnerDraw = false;
                            this.winnerbuttonDisabled = false;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }

                        }
                    });
            } catch (error) {
                this.error.api = error;
                this.winnerDraw = false;
                this.winnerbuttonDisabled = false;

            }
        },
    },

});
</script>

<style scoped>
.delete-thumbnail {
    width: 24px;
    height: 24px;
    font-size: 10px;
}
</style>
